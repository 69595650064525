import { gql } from '@apollo/client'
import { TEAM_FIELDS, GET_TEAMS } from 'modules/Admin'

export { GET_TEAMS }

export const CREATE_TEAM = gql`
  ${TEAM_FIELDS}
  mutation($input: TeamInput) {
    createTeam(input: $input) {
      ...TeamFields
    }
  }
`

export const UPDATE_TEAM = gql`
  ${TEAM_FIELDS}
  mutation($input: TeamUpdateInput) {
    updateTeam(input: $input) {
      ...TeamFields
    }
  }
`
