import { gql } from '@apollo/client'
export const GET_SEARCH_LOGS = gql`
  query($input: SearchLogsInput) {
    getSearchLogs(input: $input) {
      data {
        id
        created_at 
        user {
          id
          email
          first_name
          last_name
          team_id
          label_id
        }
        search_query
        search_results {
          productType
          product
          artistName
        }
      }
      total
    }
  }
`
