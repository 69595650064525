import { gql } from '@apollo/client'

const CAMPAIGN_FIELDS = gql`
    fragment CampaignFields on Campaign {
        artist
        id
        isrcs
        info
        group_id
        artistId
        family_id
        created_at
        label {
            id
            name
            alias
        }
        name
        product
        reach
        category
        playlists {
            PLAYLIST_NAME
            PLAYLIST_ID
        }
        team {
            id
            name
            alias
        }
        type
        user {
            id
            first_name
            last_name
            email
        }
    }
`

export const GET_CAMPAIGNS = gql`
    ${CAMPAIGN_FIELDS}
    query ($input: GetCampaignsInput){
        getCampaigns(input: $input) {
            data {
                ...CampaignFields,
            }
            total
        }
    }
`

export const CREATE_CAMPAIGN = gql`
    ${CAMPAIGN_FIELDS}
    mutation($input: CampaignInput) {
        createCampaign(input: $input) {
            ...CampaignFields,
        }
    }
`

export const DELETE_CAMPAIGN = gql`
    mutation($id: String) {
        deleteCampaign(id: $id) {
            result,
        }
    }
`

export const SEARCH_ARTISTS = gql`
    query($input: GcdmArtistSearchInput) {
        searchGcdmArtists(input: $input) {
            items {
                artistDisplayTitle
                artistId
                formatCode
                company
                country
                genre
            }
            total
        }
    }
`

export const SEARCH_PLAYLIST = gql`
    query($input: GcdmArtistSearchInput) {
        searchPlaylists(input: $input) {
            items {
                ACCOUNT
                PLAYLIST_ID
                PLAYLIST_NAME
                OWNER_PROFILE
            }
            total
        }
    }
`

export const SEARCH_PRODUCTS = gql`
    query($input: GcdmProductSearchInput) {
        searchGcdmProducts(input: $input) {
            items {
                artistDisplayTitle
                artistId
                category
                displayTitle
                ean
                familyId
                gpid
                gpidType
                isrcList
                siblingIdList
                mediaType
                siblingId
                subType
                productType
                type
                upc
            }
            total
        }
    }
`

export const SEARCH_CATEGORIES = gql`
  query {
    getCategories {
      id
      name
    }
  }
`
