export const DEFAULT_SORT_DIRECTION = 'ASC'
export const DEFAULT_SORT_FIELD = 'name';

export const SORT_FIELD_PARAM = 'sortBy'
export const SORT_DIRECTION = 'sortDirection'

export const PAGE_PARAM = 'page'
export const SEARCH_TERM_PARAM = 'term'

export * from './graphql'
