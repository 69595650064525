import React, { FC, useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Outlet, useLocation } from 'react-router-dom'
import { Navigation } from './components/Navigation'
import { AddButton } from './components/AddButton'
import { config, TEntities, useResolver } from 'modules/Admin'
import { ScrollToTopButton } from 'components';


import styles from './admin.module.scss'
import Heading from "../../components/Heading/Heading";
import { useReactiveVar } from "@apollo/client";
import { activeLabelVar, currentUserVar } from "../../modulesCommon/store";
import { useApplyPermissions } from "./permissions";

export const AdminPage: FC = () => {
  const location = useLocation();
  const currentUser = useReactiveVar(currentUserVar)

  const active = useMemo<TEntities>(
    () => {
      const path = location.pathname.split('/')
      return path[2] as TEntities;
    },
    [location],
  )
  const entities = useMemo<TEntities[]>(
    () => Object.values(config)
      .filter((entity) => entity.ability?.(currentUser) ?? true)
      .map(({ name, title }) => name),
    [currentUser],
  )

  const activeEntity = useApplyPermissions(config[active])

  const { resolver } = useResolver(activeEntity)

  const [isModalOpen, setModalStatus] = useState<boolean>(false)

  const methods = useForm({ resolver, reValidateMode: 'onChange' })

  const handleCreate = useCallback(
    () => {
      const values = activeEntity.fields.reduce((a, v) => ({ ...a, [v.name]: '' }), {})
      activeLabelVar(currentUser?.team?.label_id || '')
      methods.reset({ ...values, label: currentUser?.team?.label_id || '' })
      setModalStatus(true)
    },
    [activeEntity.fields, methods, currentUser],
  )

  return (
    <main className='main'>
      <section className='inner'>
        <Heading heading="Admin"/>
        <FormProvider {...methods}>
          <div className={styles.btns}>
            <Navigation entities={entities}/>
            {activeEntity.fields.length > 0 && <AddButton name={activeEntity.title} handleOpen={handleCreate}/>}
          </div>
          <div className={styles.table}>
            <Outlet context={{ setModalStatus, isModalOpen, entity: activeEntity }}/>
          </div>
        </FormProvider>
        <ScrollToTopButton/>
      </section>
    </main>
  )
}
