import { FC, useCallback, useMemo, useState } from 'react'
import { Button, Overlay } from '@wmgtech/legato'
import { NavLink } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'

import UserInfo from 'components/UserInfo/UserInfo'

import styles from './Menu.module.scss'
import { MenuProps } from "./Menu.props";
import cn from 'classnames';
import { useReactiveVar } from "@apollo/client";
import { currentUserVar } from "../../modulesCommon/store";
import ConditionRender from "../ConditionRender/ConditionRender";
import useIsAdmin from "../../shared/hooks/useIsAdmin";


const Menu: FC<MenuProps> = ({ user }) => {
  const { oktaAuth } = useOktaAuth()
  const isAdmin = useIsAdmin(user?.role)
  const [isOpen, setStatus] = useState<boolean>(false)

  const trigger = useMemo(
    () => (
      <Button
        containerStyle="solid"
        colorType="secondary-white"
        className={cn("burger", { "closed": !isOpen })}
        icon="bars"
      />
    ),
    [isOpen],
  )

  const onLogout = useCallback(async () => oktaAuth.signOut(), [oktaAuth])

  return (
    <>
      <Overlay
        className="headerMenu"
        placement="bottom-end"
        open={isOpen}
        onOpenChange={setStatus}
        trigger={trigger}
      >
        <div className={styles.container}>
          <UserInfo user={user} />

          <div className={styles.list}>
            <ConditionRender condition={isAdmin} component={
              <NavLink to={`/admin/users`} onClick={() => setStatus(false)} className={styles.item}>
                <div className={styles.admin}>
                  <div>Admin</div>
                </div>
              </NavLink>
            }/>
            <NavLink to={`/about`} className={styles.item} onClick={() => setStatus(false)}>
              <div className={styles.admin}>
                <div>About ACID</div>
              </div>
            </NavLink>
            <NavLink to={`/faq`} className={styles.item} onClick={() => setStatus(false)}>
              <div className={styles.admin}>
                <div>FAQs</div>
              </div>
            </NavLink>


            <a href="https://www.wmg.com/who-we-are" className={styles.item}>
              About Warner Music Group
            </a>
            <a href="https://privacy.wmg.com/ecom/privacy-policy" className={styles.item}>
              <div>Privacy Policy</div>
            </a>
            <NavLink to={`/terms-of-use`} className={styles.item} onClick={() => setStatus(false)}>
              <div>Terms & Conditions</div>
            </NavLink>
            <div className={styles.item} onClick={onLogout}>
              Logout
            </div>
          </div>
          <div className={styles.footer}>
            <div>WARNER MUSIC GROUP</div>
            <div>Copyright ©2023 Warner Music Inc.</div>
          </div>
        </div>
      </Overlay>
    </>
  )
}

export default Menu
