import { InMemoryCache } from '@apollo/client'

import { CommonStore } from 'modulesCommon/store'


export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        ...CommonStore,
      },
    },
  },
})
