import { Link, useNavigate } from 'react-router-dom'
import { ErrorPageContent, ScrollToTopButton } from 'components'
import styles from './ClientErrorPage.module.scss'

const ClientErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div className="page">
      <ErrorPageContent
        title="OOPS!"
        description={
          <div>
            We’re not quite sure what went wrong. You can go <span onClick={() => navigate(-1)}
                                                                   className={styles.link}> back </span>, or try looking
            on our
            <Link className={styles.link} to={`/faq`}> FAQ </Link>
            page
            for further assistance.
          </div>
        }
        children={<div className={styles.info}>We can’t seem to find the page you’re looking for. </div>}
      />
      <ScrollToTopButton/>
    </div>
  )
}

export default ClientErrorPage
