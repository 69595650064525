import React from 'react';
import { Outlet } from 'react-router-dom';
import Loading from './Loading';
import { useHeader } from "modulesCommon/Header/services";
import { TermOfUseModal } from "modulesCommon/TermOfUse/TermOfUse";

export const SecurePolicyAuth: React.FC = () => {
    const { isPolicyAccepted, userLoading } = useHeader()

    if (userLoading) {
        return (<Loading />);
    }

    if (!isPolicyAccepted) {
        return (
            <>
                <TermOfUseModal />
            </>
        )
    }

    return <Outlet />
}
