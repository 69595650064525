import { FC } from 'react'
import { Logo } from '@wmgtech/legato'

import { ErrorPageContentProps } from './ErrorPageContent.props'
import styles from './ErrorPageContent.module.scss'

import { Link } from 'react-router-dom'

const ErrorPageContent: FC<ErrorPageContentProps> = ({ title, description, children }) => {
  return (
    <div className={styles.error}>
      <h1 className={styles.title}>{title}</h1>
      {children}
      <p className={styles.description}>{description}</p>
      <Link className={styles.box} to={'/'} >
        <Logo
          appName=""
          className={styles.logo}
          logo="https://legato.dev.wmg.com/assets/wmg/rm_logo_black.svg"
        />
          ACID | Warner Music Group
      </Link>
    </div>
  )
}

export default ErrorPageContent
