import { FC, useMemo } from 'react'
import { Icon } from '@wmgtech/legato'
import styles from './campaign-type.module.scss'

interface IProps {
  type: any
}

export const CampaignType: FC<IProps> = ({ type }) => {
  const name = useMemo<'album' | 'compact-disc' | 'cloud-music' | 'album-collection'>(() => {
    if (type === 'Single') return 'compact-disc'
    if (type === 'Playlist') return 'album-collection'
    if (type === 'Other') return 'cloud-music'
    return 'album'
  }, [type]);

  return type ? (
    <>
      <Icon name={name} className={styles.icon}/>
      {type}
    </>
  ) : null
}
