import { makeVar, ReactiveVar } from '@apollo/client'

export const CommonStore = {
  isFirstLogin: {
    read: () => isFirstLoginVar(),
  },
  token: {
    read: () => tokenVar(),
  },
  currentUser: {
    read: () => currentUserVar(),
  },
  activeLabel: {
    read: () => activeLabelVar(),
  },
}

export const isFirstLoginVar: ReactiveVar<boolean> = makeVar<boolean>(false)

export const activeLabelVar: ReactiveVar<any> = makeVar<any>('')
export const tokenVar: ReactiveVar<any> = makeVar<any>('')
export const currentUserVar: ReactiveVar<any> = makeVar<any>({})
