import { FC, useCallback } from 'react'
import { useController } from 'react-hook-form'
import { InputGroup, Select } from '@wmgtech/legato'

import { useDataset } from 'modules/Admin'
import { validationAdapter } from './helpers'

import { IFieldError, IFormSelectProps, IOption } from './types'

export const FormSelect: FC<IFormSelectProps> = ({
                                                   name,
                                                   label,
                                                   placeholder,
                                                   datasetName,
                                                   required,
                                                   disabled,
                                                   onChangeForm,
                                                 }) => {
  const { field: { onChange, ref, ...rest }, formState: { errors } } = useController({ name })


  const { options, loading } = useDataset(datasetName)

  const handleChange = useCallback(
    (data: IOption) => {
      onChange(data.key);
      if (onChangeForm) {
        onChangeForm(name, data)
      }
    },
    [onChange, onChangeForm, name],
  )

  const validation = useCallback(
    (error?: IFieldError) => ({
      required: required,
      pending: loading,
      ...validationAdapter(error),
    }),
    [loading, required],
  )

  return (
    <InputGroup label={label} validation={validation(errors[name] as IFieldError)}>
      <Select<IOption>
        onSelect={handleChange}
        placeholder={placeholder}
        list={options}
        disabled={disabled}
        {...rest}
      />
    </InputGroup>
  )
}
