import { FC } from 'react'
import { Button } from '@wmgtech/legato'
import { FormRadioGroup } from 'components/Fields'
import { CAMPAIGN_REACH, TAB_TYPE, useCampaign, useGenerateCampaignForm } from 'modules/Campaign'
import {
    OtherGenerateCampaignForm,
    ReleaseGenerateCampaignForm,
    PlaylistGenerateCampaignForm,
} from './CampaignForm'

import styles from './form.module.scss'

interface Props {
  handleClose(): void

  setCampaign(data: {
    id: string
    name: string
    artist: string
    product: string
  }): void
}

export const GenerateCampaignForm: FC<Props> = ({ handleClose, setCampaign }) => {
  const { createCampaign } = useCampaign()
  const { category, onSubmit, isDisabled, handleSubmit, onResetForm } = useGenerateCampaignForm({
    createCampaign,
    setCampaign,
  })

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <FormRadioGroup
        name='productCategory'
        label='Product Category'
        required
        onChangeEvent={onResetForm}
        options={TAB_TYPE.map(e => ({ title: e, name: e }))}
      />
      <FormRadioGroup
        name='reach'
        label='Department'
        required
        options={CAMPAIGN_REACH.map(e => ({ title: e, name: e }))}
      />
      {category === 'Release' && <ReleaseGenerateCampaignForm />}
      {category === 'Playlist' && <PlaylistGenerateCampaignForm />}
      {category === 'Other' && <OtherGenerateCampaignForm />}
      <div className={styles.btns}>
        <Button onClick={handleClose} colorType="secondary-black" containerStyle='outline' >Cancel</Button>
        <Button disabled={isDisabled} htmlType='submit'>Generate</Button>
      </div>
    </form>
  )
}
