import set from 'lodash/fp/set'

export type TDataReader<T> = {
  read(): T | undefined
} | T

export function wrapPromise<T>(promise: Promise<T>): TDataReader<T> {
  let status = "pending";
  let result: T;
  let suspender = promise.then(
    r => {
      status = "success";
      result = r;
    },
    e => {
      status = "error";
      result = e;
    },
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
}

export function updateList<T extends Record<string | 'id', any>>(record: T, list?: ReadonlyArray<T>): ReadonlyArray<T> {
  if (typeof list === 'undefined') return []
  const idx = list.findIndex((f) => f.id === record.id)
  if (idx < 0) return list
  return set(idx)(list[idx])(list)
}

export const downloadCsv = (data: Blob, name: string): void => {
  const blob = new Blob([data], { type: 'text/csv' })
  const link = window.document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = name
  link.setAttribute('style', 'display:none')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
