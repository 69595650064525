import { useQuery } from "@apollo/client";
import { TPaginatedResponse } from "../../../shared/types";
import { useMemo } from "react";
import compose from "lodash/fp/compose";
import { useSearchParams } from "react-router-dom";
import { GET_SEARCH_LOGS } from "../constants";
import { ILogSearch, ISearchLogsResponse, ISearchLogsVariables } from "../types";
import { pageParams, searchTerm, sortParams } from "../helpers";

export const useSearchLogs = (limit?: number) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getSearchLogsVariables = useMemo<ISearchLogsVariables>(
    () => compose(
      pageParams(searchParams),
      sortParams(searchParams),
      searchTerm(searchParams),
    )({
      input: {
        limit: limit || 10,
        offset: 0,
        // sortOrder: {
        //   direction: DEFAULT_SORT_DIRECTION,
        //   field: DEFAULT_SORT_FIELD,
        // },
      },
    }),
    [limit, searchParams],
  )

  const { data: searchLogs, loading } = useQuery<ISearchLogsResponse, ISearchLogsVariables>(GET_SEARCH_LOGS, {
    variables: getSearchLogsVariables,
    skip: typeof limit === 'undefined',
  })

  return {
    searchLogs: useMemo<TPaginatedResponse<ILogSearch>>(
      () => ({
        data: searchLogs?.getSearchLogs?.data ?? [],
        total: searchLogs?.getSearchLogs?.total ?? 0,
      }),
      [searchLogs],
    ),
    loading: loading,

  }
}
