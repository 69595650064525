import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useHeader } from 'modulesCommon/Header/services'
import useIsAdmin from 'shared/hooks/useIsAdmin'
import Loading from './Loading'

export const RequiredAdminAuth: React.FC = () => {
    const { user, userLoading } = useHeader()
    const isAdmin = useIsAdmin(user?.role)

    if (userLoading) return <Loading />
    else {
      if (!isAdmin) return <Navigate to="/" replace />
      return <Outlet />
    }
}
