export const DEFAULT_SORT_DIRECTION = 'ASC'
export const DEFAULT_SORT_FIELD = 'name';

export const SORT_FIELD_PARAM = 'sortBy'
export const SORT_DIRECTION = 'sortDirection'

export const ROLES = {
  admin: "Global Admin",
  "label-manager": "Label Admin",
  "campaign-owner": "Team Member",
}


export * from './graphql'
