import { ErrorPageContent } from 'components'
import { Link, useNavigate, useRouteError } from "react-router-dom";
import styles from './ServerErrorPage.module.scss'
import { ApolloError, isApolloError } from "@apollo/client";

const ServerErrorPage = () => {
  const navigate = useNavigate();
  const error = useRouteError() as ApolloError
  let title = ''
  let description = ''
  if (isApolloError(error)) {
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    if (error.graphQLErrors.length > 0) {
      const gqlError = error.graphQLErrors[0]

      // @ts-ignore
      if (gqlError.errorType === 'NotExists') {
        title = 'Not Found'
        description = gqlError.message
        // @ts-ignore
      } else if (gqlError.errorType === 'PermissionDenied') {
        title = 'Access Denied'
        description = gqlError.message
      } else { // @ts-ignore
        if (gqlError.errorType === 'AuthorizationError') {
          title = 'Authorization Error'
          description = error.message
        }
      }
    } else {
      title = error.name
      description = error.message
    }
    /* eslint-enable @typescript-eslint/ban-ts-comment */
  }
  return (
    <div className="page">
      <ErrorPageContent
        title={'OOPS!'}
        description={
          description ? (
            <div>{description}</div>
          ) : (
            <div>
              We’re not quite sure what went wrong. You can go <span onClick={() => navigate(-1)}
                                                                     className={styles.link}> back </span>, or try
              looking
              on our
              <Link className={styles.link} to={`/faq`}> FAQ </Link>
              page
              for further assistance.
            </div>
          )
        }
        children={<div className={styles.info}>{title || 'We can’t seem to find the page you’re looking for.'}</div>}
      />
    </div>
  )
}

export default ServerErrorPage
