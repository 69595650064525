import { FC } from 'react'
import { IconButton } from '@wmgtech/legato'

import styles from './action-button.module.scss'

interface IProps {
  handleEdit(): void
  handleDelete?(): void
  handleExtendAccount?(): void
}

export const ActionButton: FC<IProps> = ({ handleEdit, handleDelete, handleExtendAccount }) => {
  return (
    <div className={styles.container}>
      <IconButton
        message='Edit'
        className={styles.btn}
        icon="pen"
        colorType="primary"
        onClick={handleEdit}
      />
      {
        typeof handleExtendAccount === 'function' ? (
          <IconButton
            message='Extend user account'
            className={styles.btn}
            icon="trash-can-arrow-up"
            colorType="warning"
            onClick={handleExtendAccount}
          />
        ) : null
      }
      {
        typeof handleDelete === 'function' ? (
          <IconButton
            message="Delete"
            className={styles.btn}
            icon="trash-can"
            colorType="danger"
            onClick={handleDelete}
          />
        ) : null
      }
    </div>
  )
}
