import compact from 'lodash/fp/compact'
import set from 'lodash/fp/set'
import { SORT_DIRECTION, SORT_FIELD_PARAM, PAGE_PARAM, SEARCH_TERM_PARAM } from './constants'
import { ICampaign, IGetCampaignInput, TFilterKeys } from './types'

export function getCampaignName(data: ICampaign): string {
  const reach = data.reach === 'Domestic' ? 'DOM' : 'INTL'
  return compact([reach, data.artist, data.product, data.category, `ACID:${data.id}`, data.info]).join(' - ')
}

export function getCreator(data: ICampaign): string {
  return compact([data.user.first_name, data.user.last_name]).join(' ')
}

export function getCurrentPage(searchParameter: URLSearchParams): number {
  if (searchParameter.has(PAGE_PARAM)) {
    const pageString = searchParameter.get(PAGE_PARAM)
    if (typeof pageString === 'string') {
      const page = parseInt(pageString, 10)
      if (!isNaN(page)) return page
    }
  }
  return 1
}

export function searchTerm<T extends IGetCampaignInput>(params: URLSearchParams) {
  return (data: T): T => {
    if (params.has(SEARCH_TERM_PARAM)) {
      const search = params.get(SEARCH_TERM_PARAM)
      return set(['input', 'search'])(search)(data)
    }
    return data
  }
}

export function sortParams<T extends IGetCampaignInput>(params: URLSearchParams) {
  return (data: T): T => {
    if (params.has(SORT_FIELD_PARAM)) {
      return set(['input', 'sortOrder'])({
        direction: params.get(SORT_DIRECTION),
        field: params.get(SORT_FIELD_PARAM),
      })(data)
    }
    return data
  }
}

export function pageParams<T extends IGetCampaignInput>(params: URLSearchParams) {
  return (data: T): T => {
    if (params.has(PAGE_PARAM)) {
      const offset = (getCurrentPage(params) - 1) * data.input.limit
      return set(['input', 'offset'])(offset)(data)
    }
    return data
  }
}

export function filtersParams<T extends IGetCampaignInput>(params: URLSearchParams, key: TFilterKeys) {
  return (data: T): T => {
    if (params.has(key)) {
      return set(['input', 'filter', key])(params.getAll(key))(data)
    }
    return data
  }
}
