import { FC, useCallback, useMemo } from 'react'
import { Table } from '@wmgtech/legato'

import { ActionButton } from 'components/ActionButton'
import { ColumnsType, ICategory, ModalForm, useAdmin } from 'modules/Admin'
import { ConditionRender, SkeletonTable } from 'components'
import { useCategories } from 'modules/Categories/services'
import { useConfirmation } from 'components/Confirmation'
import styles from './category.module.scss'

interface ICategoryInput {
  name: string
  id?: string
}

export const Categories: FC = () => {
  const { categories, loading, createCategory, updateCategory, deleteCategory } = useCategories()

  const { handleEdit } = useAdmin()

  const onSubmit = useCallback(
    async ({ id, name }: ICategoryInput) => {
      if (typeof id === 'undefined') {
        return createCategory(name)
      }
      return updateCategory(id, name )
    },
    [createCategory, updateCategory],
  )

  const { onConfirm, element } = useConfirmation(
    deleteCategory,
    (message) => (<>Are you sure you want to delete category <b>{message}</b> ?</>),
    'Delete',
  )

  const columns = useMemo<ColumnsType<ICategory>[]>(
    () => [
      {
        dataIndex: 'name',
        title: 'Name',
        key: 'name',
        sortable: false,
        align: 'left',
      },
      {
        dataIndex: 'action',
        key: 'action',
        sortable: false,
        width: '63px',
        align: 'center',
        render: (el: any, record: any) => (
          <ActionButton
            handleEdit={() => handleEdit((record as any))}
            handleDelete={() => onConfirm({ data: record.id, message: `${record.name}` })}
          />
        ),
      },
    ],
    [handleEdit, onConfirm],
  )

  return (
    <div className={styles.container}>
    <ConditionRender condition={!loading} component={
      <Table
        data={categories}
        columns={columns}
        rowKey={({ id }) => id}
        pagination={false}
      />
    } secondComponent={<SkeletonTable lines={10}/>}
    />
      {element}
      <ModalForm onSubmit={onSubmit}/>
    </div>
  )
}
