import { FC } from 'react'
import { Input, Label, Tooltip, Icon } from '@wmgtech/legato'
import { useController } from 'react-hook-form'
import { IGenerateCampaignForm } from 'modules/Campaign'

export const InfoField: FC = () => {
  const { field } = useController<IGenerateCampaignForm, 'info'>({ name: 'info' })

  return (
    <div>
      <Label
        text='Additional Info'
        suffix={(
          <Tooltip
            message="Add additional information to your campaign name such as phase, platform, etc"
            colorType="secondary-black"
          ><Icon name='circle-info' /></Tooltip>
        )}
        className="lgt-input-group__label"
      />
      <Input placeholder="Add customized campaign name info" {...field} crossOrigin="" />
    </div>
  )
}
