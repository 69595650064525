import { gql } from '@apollo/client'
import { LABEL_FIELDS, GET_LABELS } from 'modules/Admin'

export { GET_LABELS }

export const CREATE_LABEL = gql`
  ${LABEL_FIELDS}
  mutation($input: LabelInput) {
    createLabel(input: $input) {
      ...LabelField
    }
  }
`

export const UPDATE_LABEL = gql`
  ${LABEL_FIELDS}
  mutation($input: LabelUpdateInput) {
    updateLabel(input: $input) {
      ...LabelField
    }
  }
`
