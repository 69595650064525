import { useCallback } from 'react'
import { Resolver } from 'react-hook-form'
import compose from 'lodash/fp/compose'

import { isRequired, either } from 'components/Fields/helpers'
import { IEntityConfig } from '../types'

export const useResolver = ({ fields }: IEntityConfig) => {

  const errors = useCallback(
    (values: Record<string, any>) =>
      fields.reduce((a, v) => compose(
        either(Boolean(v.required), isRequired(values, v.name)),
      )(a), {}),
    [fields],
  )

  const resolver = useCallback<Resolver>(
    (values) => ({
      values,
      errors: errors(values),
    }),
    [errors],
  )

  return {
    resolver,
  }
}