import React, { FC } from 'react'
import { Heading } from 'components'
import styles from './TermOfUse.module.scss'

export const TermOfUse: FC = () => {
  return (
    <main className={`${styles.main} main`}>
      <section className='inner'>
        <Heading heading="Terms of Use"/>
        <div className={styles.article}>
          <h1>Warner Music: Terms of Use</h1>
          <p><i>These Terms of Use (the "Terms") govern your use of this tool ("Tool") as well as such products, features
            and
            other services available on or through it (together the "Service(s)"), except where we expressly state that
            separate terms apply.</i></p>
          <p><i>The Services are provided to you by Warner Music International Services Limited (referred to as “we,” “us”
            or
            "our" in these Terms). When we refer to "you" or "your" in these Terms, we are referring to you as the user
            of
            the Services. References to our “Affiliates” in these Terms means the other companies within the Warner
            Music corporate group.</i></p>
          <ol></ol>
          <h2>1 Using this Tool</h2>
          <p>1.1 By using the Tool, you agree to these Terms and any additional guidelines or rules that are referenced
            within it. Together, these make up the entire agreement between you and us for your use of our
            Services. They supersede any prior agreements between you and us. You agree that you have the
            legal right and authority to enter into these Terms.</p>
          <p>1.2 Please read these Terms carefully and make sure you understand them. If you do not understand the
            Terms, or do not accept any part of them, please do not use the Services.</p>
          <p>1.3 Your access to the Services may also be governed by the terms and conditions of third party providers
            who make available other applications or services to you through this Tool. See also Section 4 below.
          </p>
          <p>1.4 You may need to register and/or hold an account to use the Services. You must provide accurate and
            complete registration information and keep this up to date. Your user name and password (and any
            identification codes or other information) are for your personal use only and should be kept
            confidential. We reserve the right (acting reasonably) to prevent your use of a user name or require
            you to change it.</p>
          <p>1.5 We may terminate your use of the Services for your breach of these Terms, including if you repeatedly
            engage in copyright infringement via or in connection with the Services. See also Section 10.</p>
          <p>1.6 Where the parties have entered into a master services agreement or other agreement for services
            related to the use of or information contained within the Tool, it is agreed that to the extent any terms
            conflict with these Terms, the terms of such agreement shall prevail.</p>
          <h2>2 Privacy</h2>
          <p>2.1 We explain in our Privacy Policy, located here <a
            href="https://privacy.wmg.com/wmi/privacy">https://privacy.wmg.com/wmi/privacy</a>, how we process
            your personal data when you use the Services. If you make your personal data available to a third
            party, the third party's privacy policy may apply to how this personal data is used. If you choose to
            make any of your personal data publicly available on the Services, you do so at your own risk.</p>
          <h2>3 Permissions and restrictions</h2>
          <p>3.1 Unless otherwise stated by us, the Services are only available only for people aged 18 or older.</p>
          <p>3.2 Individuals not permitted to use the Services include: (a) individuals from countries listed, or those
            named as sanctioned individuals, on sanctions lists (or similar) the United States, including the
            Department of the Treasury’s Office of Foreign Assets Control, or by the United Nations Security
            Council, the European Union, the United Kingdom under sanctions regimes; and (b) those who have
            had their account(s) suspended or terminated previously by us, unless we notify you otherwise in
            writing.</p>
          <p>3.3 You are not allowed to:</p>
          <ul>
            <li>use the Services for any fraudulent or unlawful purpose;</li>
            <li>access, copy, modify, decompile, reverse engineer, download, distribute, transmit, broadcast,
              display, translate, rent, lease, loan, licence, sell, or otherwise exploit or use any part of the
              Services except: (a) as specifically permitted by the Services; (b) with prior written permission
              from us and, if applicable, the respective rights holders; or (c) as permitted by applicable law. This
              includes not removing any copyright, trademark or other proprietary rights notice from the Services
              or materials within them;
            </li>
            <li>attempt to gain unauthorised access to our Services or interfere with or disrupt the operation of the
              Services or networks used to make the Services available (including security-related features) or
              violate the policies of such networks;
            </li>
            <li>restrict or inhibit any other person from using the Services (including by hacking or defacing any
              portion of the Services);
            </li>
            <li>knowingly introduce any viruses, trojans, worms, logic bombs or other material that is malicious or
              technologically harmful;
            </li>
            <li>use any robots, botnets, scrapers or spiders, site search/retrieval application or other manual or
              automatic device, algorithm, code, process or methodology to retrieve, index, scrape, data mine or
              in any way reproduce/republish, obtain a copy of, monitor or circumvent the Services, without our
              express prior written consent;
            </li>
            <li>use any automated analytical technique aimed at analysing text and data in digital form to
              generate information which includes but is not limited to patterns, trends and correlations. The
              provisions in this clause should be treated as an express reservation of our rights in this regard,
              including for the purposes of Article 4(3) of Digital Copyright Directive ((EU) 2019/790). This
              clause shall not apply insofar as (but only to the extent that) we are unable to exclude or limit text
              or data mining or web scraping activity by contract under the laws which are applicable to us;
            </li>
            <li>use any part of the Services for commercial purposes without our written consent. This means you
              should only access and use the Services for personal use, and you must not use the Services to:
              (a) advertise or offer to sell or buy any goods or services without our express prior written consent;
              or (b) use the Service to distribute unsolicited promotional or commercial content or other
              unwanted or mass solicitations (spam).
            </li>
          </ul>
          <h2>4 Third-party links and services</h2>
          <p>4.1 The Services may include links to websites, products and services owned and operated by third
            parties. These Terms only apply to this Tool and do not apply to that third-party content, or any other
            website, tool or app operated by the Warner Music Group. You should review the safety, content and
            validity of those websites, products and services, and their terms and policies, very carefully. We have
            no control over the third party's content and we are not responsible for any third party's websites,
            products and services, or their availability. Your use of such third-party content is at your own risk.</p>
          <h2>5 Our duties to you</h2>
          <p>5.1 We will provide the Services with reasonable care and skill.</p>
          <p>5.2 The Services and Tool are provided "as is" and you access them at your own risk. This means that,
            other than as expressly stated in these Terms or as required by law, we do not make any specific
            promises about the Services or Tool. For example, we do not promise and cannot guarantee that:</p>
          <ul>
            <li>the Services will be appropriate or available for everyone, or in all locations, and we are free to
              decide this;
            </li>
            <li>the Services or the materials and information provided through them are accurate, complete,
              uninterrupted, secure, error-free, up to date or able to meet everyone's needs;
            </li>
            <li>any content you post or otherwise submit will be accessible or stored on the Services, or that there
              will not be restrictions on the volume or type of content you may be able to post; or
            </li>
            <li>any software, hardware, equipment or system using the Services will function in any manner.</li>
          </ul>
          <h2>6 Liability</h2>
          <p>6.1 By law, consumers have certain rights that cannot be excluded or altered by contract terms like these.
            Nothing in these Terms affects those rights you may have as a consumer and, in particular, we do not
            seek to limit or exclude our liability for death or personal injury as a result of our negligence, fraud or
            fraudulent misrepresentation.</p>
          <p>6.2 We, our Affiliates and our artists, representatives and providers will not be responsible for any loss
            or
            damage you may experience in connection with your use of the Services including which is:</p>
          <ul>
            <li>caused by an event beyond our reasonable control or which we could not have reasonably
              foreseen;
            </li>
            <li>the result of any User Content (as defined in Section 8.1) or for the defamatory, offensive or illegal
              conduct of any user;
            </li>
            <li>not directly caused by us; or</li>
            <li>a business loss.</li>
          </ul>
          <h2>7 Ownership of information and materials</h2>
          <p>7.1 Unless we say otherwise in these Terms, we, our Affiliates, artists or our respective licensors and
            providers own the information and materials made available through the Services. This includes any
            information or materials that we, our Affiliates or our artists' or their agents, representatives or third
            parties post to the Services. Such information and materials are protected by copyright, trademark,
            patent and/or other proprietary rights and laws around the world. All such rights are reserved.</p>
          <p>7.2 Without prejudice to any other provisions in these Terms:</p>
          <ul>
            <li>save as may be expressly permitted by the Services (such as user functionality and in-built tools
              we may offer in the Tool), you are strictly prohibited from transferring or making available to any
              other person any content on the Services, including but not limited to any audio or audio visual
              works (including artists' music and videos);
            </li>
            <li>you may not use our, or any of our Affiliates' or artists' trade names, trademarks and service marks
              in connection with any product or service that is not ours, or in any manner that is likely to cause
              confusion;
            </li>
            <li>the respective rights holder(s) retain all ownership in the content and materials available (including
              audio) on or through the Services and do not grant you any ownership whatsoever, including but
              not limited to copyright, in such content and materials.
            </li>
          </ul>
          <h2>8 Content you post</h2>
          <p>8.1 The Services may contain areas where you and other users of our Services can post content including
            materials and information, including, without limitation, text, images, photos, graphics, music, videos,
            audio-visual works, data, files, links and other materials (“User Content”). Save as otherwise
            permitted by us, the posting of User Content is strictly subject to these Terms.</p>
          <p>8.2 Please keep in mind that User Content you post or share will be publicly accessible, and may be used
            and re-shared by other users of our Services and across the web, so please always use caution in
            posting or sharing on our Services.</p>
          <p>8.3 Nothing in these Terms should be read as permitting you to incorporate into any User Content, any
            material or information owned by us, our Affiliates or our artists, agents, representatives, licensors and
            service providers.</p>
          <p>8.4 You are legally responsible for the User Content you post on or through the Services and your
            conduct, and for making sure this complies with local laws and regulations.</p>
          <p>8.5 You are responsible for making sure you have (and pay for) any software, hardware or services
            (including internet connectivity) needed to use the Services and that these are working properly, with
            effective security.</p>
          <p>8.6 You acknowledge that we have no obligation to consider unsolicited ideas, materials, proposals or
            suggestions you may submit in your User Content.</p>
          <p>8.7 While you will continue to own any User Content that you post (ie, this belongs to you and you keep
            your ownership rights after you post), you grant to us and our Affiliates (in respect of each such piece
            of User Content) a world-wide, royalty free, fully paid-up, non-exclusive, perpetual, irrevocable,
            transferable, and fully sub-licensable (through multiple tiers) licence, without additional consideration
            to you or any third party, to reproduce, distribute, modify, display and perform it in any format or media
            now known or hereafter developed, create derivative works from, and exercise all trademark, publicity
            and other proprietary rights for the purpose of operating, marketing and improving our services and
            promoting our artists, their music and related activities.</p>
          <p>8.8 You also grant each other user a worldwide, non-exclusive, royalty-free licence to access your User
            Content through the Services, and to use that User Content (including to reproduce, distribute, modify,
            display, and perform it) only as enabled by a feature of the Services.</p>
          <p>8.9 You agree, represent and warrant that:</p>
          <ul>
            <li>if any third party materials and information appear in your User Content, you have obtained
              express permission from all owners of, and rights holders, in such third party materials; and,
            </li>
            <li>you have obtained the written permission of every identifiable individual who appears in any User
              Content that you post, to use such individual’s name and likeness or, if they are under 18, you
              have permission of their parent or guardian (which shall be provided to us on request).
              8.10 User Content may include links to social media pages, video-sharing sites, bulletin boards and chat
              rooms. Links, materials and information within User Content have not been verified or approved by us.
              You acknowledge and agree that the views expressed by users in the User Content on our Services
              do not represent the views or values of us, our Affiliates, artists, representatives and providers.
            </li>
          </ul>
          <h2>9 Removal of User Content</h2>
          <p>9.1 We reserve the right to remove or take down some or all of your User Content without notice to you if
            we reasonably believe that any such User Content:</p>
          <ul>
            <li>breaches these Terms;</li>
            <li>causes harm to us, our Affiliates, artists, representatives and providers, including by way of
              derogatory comments or treatment; or
            </li>
            <li>puts (or is likely to put) us, our Affiliates, artists, representatives or providers in breach (or
              potential
              breach) of laws or regulations; prejudices any investigation or the safety or security of our Services
              or other users or third parties; or exposes us or our Affiliates or artists to the risk of liability.
            </li>

          </ul>
          <p>9.2 We may use automated systems that analyse your Content to help detect infringement and abuse,
            such as spam, malware, and illegal content.</p>
          <h2>10 Account suspension and termination</h2>
          <p>10.1 If any of the following happen, we may suspend or terminate your access to all or part of the
            Services:</p>
          <ul>
            <li>we determine (acting reasonably) that you materially or repeatedly breach or have breached these
              Terms;
            </li>
            <li>we are required to do so to comply with a legal requirement or court order;</li>
            <li>we reasonably believe that your conduct creates liability or harm to any user, other third party, us,
              our Affiliates or artists.
            </li>
          </ul>
          <p>10.2 If you believe that your account may have been terminated or suspended in error, please email
            acid-support@warnermusic.com.</p>
          <p>10.3 You may stop using the Services at any time. If you wish to close your account, you may do so by
            following the instructions in the ‘account settings’ section. We will delete your account information
            generally, but shall retain certain information such as your user name, (which we retain to help avoid
            re-use), your profile image, and any events, images, or videos or Content that you have previously
            added to the Services, as well as certain information for period of time for backup purposes.</p>
          <h2>11 Confidential Information.</h2>
          <p>11.1 As a result of the Services you will have access to confidential and proprietary information
            concerning
            Warner Music Group, including but is not limited to information that otherwise should be understood by
            a reasonable person to be confidential in nature, provided by a party or on its behalf, including the
            Services (the “Confidential Information”).</p>
          <p>11.2 All Confidential Information shall remain solely our property, and you shall maintain and protect the
            confidentiality of such Confidential Information with no less than a reasonable degree of care.</p>
          <p>11.3 You shall not use, duplicate in any manner, or disclose the Confidential Information to any third
            party
            or to any of your employees or agents not having a need to know other than (i) to perform services for
            which, and solely to the extent that, you are engaged by us or our Affiliate; or (ii) as required by
            applicable law. If disclosure is required by applicable law, you shall provide us with prior (if practicable
            under the circumstances) and prompt written notice thereof and, if practicable under the
            circumstances, allow us to seek a restraining order or other appropriate relief.</p>
          <p>11.4 Any disclosure by you to employees or agents shall be made pursuant to obligations of confidentiality
            that are at least as rigorous as the obligations provided herein.</p>
          <p>11.5 Upon the expiry or termination of the Terms or your engagement by us or our Affiliate (by whomsoever
            and for whatever reason) or at our request, you shall cause all Confidential Information in whatever
            medium the same is recorded or held to be returned, deleted or destroyed according to our direction.
            You may retain: (i) one copy of the Confidential Information under a continuing obligation of confidence
            if required to do so by applicable law, rule or regulation or my any competent judicial, government, a
            supervisory or regulatory body; (ii) as is created by automatic IT back-up systems, but may make no
            further use of such Confidential Information whatsoever and shall retain any such copies on the terms
            of these Terms</p>
          <p>11.6 The obligations of each party under this clause shall survive indefinitely.</p>
          <h2>12 Indemnification</h2>
          <p>12.1 You will indemnify us and our subsidiaries, affiliates, officers, agents, employees, partners, and
            licensors from any claim(s), demand(s), loss(es), or damage(s), including reasonable attorneys’ fees ,
            arising out of, or related to:</p>
          <ul>
            <li>your User Content;</li>
            <li>your use of the Services and Tool;</li>
            <li>your interactions with any other users; or</li>
            <li>your violation of the Terms.</li>
          </ul>
          <h2>13 How to contact us</h2>
          <p>13.1 If you have any questions regarding the meaning of these Terms or need support, please email
            acid-support@warnermusic.com. Emails are not necessarily secure, so please do not include
            credit card information or other sensitive information in any email to us.</p>
          <h2>14 Changes to these Terms</h2>
          <p> 14.1 Our Services are constantly evolving and we may change, modify, update or amend these Terms from
            time to time, including as needed to accurately reflect our services and practices, to promote a safe
            and secure user experience and/or to comply with applicable law. We may modify or discontinue all or
            part of the Services, charge, modify or waive fees required to use the Services, or offer opportunities
            to some or all users. We may notify you of such changes by such reasonable means as we determine,
            including by posting a revised version of these Terms.</p>
          <h3>15 Miscellaneous</h3>
          <p>15.1 Our agreement under these Terms does not create any partnership, joint venture, employer-employee,
            agency or franchisor-franchisee relationship between you and us or any of our Affiliates, artists,
            representatives and providers.</p>
          <p>15.2 If any part of these Terms is found to be unenforceable, that part will not affect the validity and
            enforceability of any other part.</p>
          <p>15.3 You may not assign, transfer or sublicense any or all of your rights or obligations under these Terms
            without our express prior written consent.</p>
          <p>15.4 No waiver by you or us of any breach or default under these Terms will be deemed to be a waiver of
            any preceding or subsequent breach or default.</p>
          <p>15.5 We may transfer our rights and obligations under these Terms to another organisation. We will use
            reasonable endeavours to inform you if this happens and in a way that the transfer will not materially
            affect your rights under the Terms.</p>
          <p>15.6 Notices to you or about other matters may be made via posts to or via the Services, providing links,
            by
            e-mail or by regular mail, in our discretion.</p>
          <p>15.7 If you do not comply with these Terms and we do not take action immediately, this does not mean that
            we are giving up any rights that we may have (such as the right to take action in the future).</p>
          <p>15.8 Subject to Section 15.9, nothing in these Terms, express or implied, is intended to confer, nor shall
            anything herein confer on, any person other than the parties to these Terms, and the respective
            successors or permitted assigns of such parties, any rights, remedies, obligations or liabilities.</p>
          <p>15.9 You agree that our Affiliates and our and their respective artists, representatives and providers who
            are content owners and service providers from whom we have obtained a licence or other rights to use
            their content and services (as applicable) in connection with the Services are third-party beneficiaries
            under these Terms with the right to enforce the provisions of these Terms that directly apply to such
            parties. Notwithstanding the immediately preceding sentence, our right to enter into, rescind or
            terminate any variation, waiver or settlement under these Terms is not subject to the consent of any
            third party.</p>
          <p>15.10 If your use of the Service ends, Sections 6 (Liability), 7 (Ownership of information and materials),
            8
            (Content you post), 9 (Removal of User Content), 14 (Changes to these Terms), 16 (Disputes between
            us) and 17 (Miscellaneous) will continue to apply. This includes the licenses granted by you
            continuing in limited cases as described in Section 8.</p>
          <h2>16 Governing Law and Jurisdiction</h2>
          <p>16.1 These Terms and any dispute or claim arising in connection with them shall be governed by the laws
            of the State of New York, U.S.A and both you and we can bring legal proceedings in connection with
            these Terms in New York, New York, U.S.A. However, if you are a consumer and your usual place of
            residence is in another country, you can also rely on the mandatory consumer protection rules that
            apply in your country of residence and this clause does not override the powers of a competent court
            in your usual country of residence to decide your claim or dispute if it has jurisdiction.</p>
        </div>
      </section>
    </main>
  )
}
