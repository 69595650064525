import set from 'lodash/fp/set'
import { IValidation, IFieldError } from './types'

export function validationAdapter(error?: IFieldError): IValidation {
  if (typeof error === 'undefined') return {}
  return { message: error.message, type: 'error' }
}

export function isRequired(values: Record<string, string | boolean | any[] | null>, name: string) {
  const value = values[name];
  return (res: Record<string, unknown>) => {
    if (((typeof value === 'string' || Array.isArray(value)) && value.length === 0) || value === null) {
      return set(name)({ type: 'error', message: 'This field is required.' })(res)
    }
    return res
  }
}

export function either<T>(condition: boolean, fn: (data: T) => T) {
  return (d: T) => {
    if (condition) return fn(d)
    return d
  }
}