import React from 'react'
import { FC } from 'react'
import '../Skeleton.scss'

const Skeleton: FC<{height?: number}> = ({ height = 20 }) => {
  return (
    <div className="pd-skeleton__flex">
      <div className="pd-skeleton__line pd-skeleton__single" style={{ height: `${height}px` }}/>
    </div>
  )
}

export default Skeleton
