import { FC, useCallback, useMemo } from 'react'
import { useSearchParams, Link } from 'react-router-dom'
import RCPagination from 'rc-pagination'
import { Icon } from '@wmgtech/legato'
import { enUsLocale } from './constants'
import { getCurrentPage } from 'modules/Campaign'

import styles from './pagination.module.scss'
import { IPaginationProps, TItemRender } from './types'

export const Pagination: FC<IPaginationProps> = ({ total, pageSize }) => {
  const [searchParameter] = useSearchParams();

  const maxPage = useMemo(
    () => Math.ceil(total / pageSize),
    [pageSize, total],
  )

  const current = useMemo<number>(
    () => getCurrentPage(searchParameter),
    [searchParameter],
  )

  const ItemRender: TItemRender = useCallback(
    (page, type, element) => {
      switch(type) {
        case 'page': {
          searchParameter.set('page', page.toString())
          return (
            <Link
              className={styles.link}
              relative='path'
              to={`?${searchParameter.toString()}`}
            >{page}</Link>
          )
        }
        case 'next': {
          const nextPage = current + 1
          if (nextPage > maxPage) return null
          searchParameter.set('page', nextPage.toString())
          return (
            <Link
              className={styles.link}
              relative='path'
              to={`?${searchParameter.toString()}`}
            >
              <Icon name="chevron-right"/>
            </Link>
          )
        }
        case 'prev': {
          const prevPage = current - 1
          if (prevPage === 0) return null
          searchParameter.set('page', prevPage.toString())
          return (
            <Link
              className={styles.link}
              relative='path'
              to={`?${searchParameter.toString()}`}
            >
              <Icon name="chevron-left"/>
            </Link>
          )
        }
        case 'jump-next': {
          return element
        }
        case 'jump-prev': {
          return element
        }
        default: {
          return null
        }
      }
    },
  [current, maxPage, searchParameter],
)

  return (
    <div className={styles.container}>
      <RCPagination
        prefixCls="lgt-pagination"
        locale={enUsLocale}
        itemRender={ItemRender}
        total={total}
        pageSize={pageSize}
        current={current}
        hideOnSinglePage
        showQuickJumper={false}
        onChange={() => null}
      />
    </div>
  )
}
