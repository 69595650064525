import { FC, createElement } from 'react'

import { FormInput, FormSelect } from 'components/Fields'
import { IField } from 'modules/Admin'
import styles from './form-builder.module.scss'

interface IProps {
  fields: ReadonlyArray<IField>
  isEdit: boolean
  onChangeForm?(name: string, data: any): void
}

const getElement = (data: IField, isEdit: boolean, onChangeForm: any) => {

  switch(data.element) {
    case 'input': {
      const { element, disabled, ...props } = data
      return createElement(FormInput, props)
    }
    case 'select': {
      const { element, disabled, ...props } = data
      return createElement(FormSelect, {
        ...props,
        onChangeForm,
        disabled: !!disabled,
      })
    }
    default:
      return null
  }
}

export const FormBuilder: FC<IProps> = ({ fields, isEdit, onChangeForm }) => {

  return (
    <ul className={styles.fields}>
      {
        fields.map((data) => (
          <li key={data.name} className={styles.field}>
            {
              getElement(data, isEdit, onChangeForm)
            }
          </li>
        ))
      }
    </ul>
  )
}
