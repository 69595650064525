import { createBrowserRouter } from "react-router-dom";
import { RequiredAdminAuth, RequiredAuth, SecurePolicyAuth } from "components/SecureRoute";
import { ClientErrorPage, ServerErrorPage, HomePage } from 'pages'
import { adminRoute } from 'pages/Admin'
import { LoginCallback } from 'modulesCommon/LoginCallback/containers'
import App from './App'
import { FAQ, About, TermOfUse } from "./pages";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ServerErrorPage/>,
    children: [
      {
        element: <RequiredAuth/>,
        children: [
          {
            element: <SecurePolicyAuth/>,
            children: [
              {
                path: '',
                element: <HomePage/>,
              },
              {
                path: "/faq",
                element: <FAQ/>,
              },
            ],
          },
          {
            path: "/terms-of-use",
            element: <TermOfUse/>,
          },
        ],
      },
      {
        path: "/auth/callback",
        element: <LoginCallback/>,
      },
      {
        path: "/admin",
        element: <RequiredAuth/>,
        children: [{
          element: <RequiredAdminAuth/>,
          children: [adminRoute],
        }],
      },
      {
        path: "/about",
        element: <About/>,
      },
      {
        path: "/500",
        element: <ServerErrorPage/>,
      },
      {
        path: "*",
        element: <ClientErrorPage/>,
      },
    ],
  },
])
