import React, { FC } from 'react'
import { GoBack } from "../index";
import styles from './Heading.module.scss'

const Heading: FC<{heading: string}> = ( { heading }) => {
  return (
    <div className={styles.heading}>
      <GoBack/>
      <h1 className="title">{heading}</h1>
    </div>
  )
}

export default Heading
