import { FC } from 'react'
import { Tooltip } from '@wmgtech/legato'
import styles from './search-item.module.scss'

interface IProps {
  title: string
  subtitle: string
}

export const SearchItem: FC<IProps> = ({ title, subtitle }) => (
  <Tooltip title={title} message={subtitle} colorType='info' className={styles.tooltip} >
    <div className={styles.wrapper}>
      <dl className={styles.container}>
        <dt>{title}</dt>
        <dd>{subtitle}</dd>
      </dl>
    </div>
  </Tooltip>
)