import { FC, useCallback, useMemo } from 'react'
import { InputGroup, RadioGroup } from '@wmgtech/legato'
import { useController } from 'react-hook-form'

import { IFormRadioGroupProps } from './types'

export const FormRadioGroup: FC<IFormRadioGroupProps> = ({ name, label, required, options, onChangeEvent }) => {
  const { field: { ref, onChange, ...field } } = useController({ name })

  const validation = useMemo(
    () => ({
      required: required,
    }),
    [required],
  )

  const onHandleChange = useCallback((data: string) => {
      onChange(data)
      onChangeEvent && onChangeEvent(data)
  }, [onChange, onChangeEvent])

  return (
    <InputGroup label={label} validation={validation}>
      <RadioGroup
        {...field}
        onChange={onHandleChange}
        size="md"
        list={options}
      />
    </InputGroup>
  )
}
