import React, { FC, useEffect, useState } from 'react'
import classnames from 'classnames'

import { ActivationCodeProps } from './ActivationUrl.props'

import styles from './ActivationUrl.module.scss'
import { Modal } from "@wmgtech/legato";

const ActivationCode: FC<ActivationCodeProps> = ({ activationCode, isUserExistInOkta }) => {
  const [copied, setCopied] = useState(false)
  const [closed, setClosed] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(activationCode)
    setCopied(true)
  }

  useEffect(() => {
    if (activationCode) {
      setClosed(false)
    }
    return () => {
    }
  }, [activationCode])

  return (
    <>
      <Modal
        title={""}
        onClose={() => {
          setClosed(true)
        }}
        isOpen={!closed}
        shouldCloseOnOverlayClick={false}
      >
        <div className={styles.notification}>
          <h2 className={styles.title}>
            {isUserExistInOkta ? 'User has been created successfully!' : 'Activation link'}
          </h2>
          {!isUserExistInOkta && (
            <div className={styles.hint}>
              <div className={styles.hint}>
                <i className="fas fa-info-circle"/>
                An email with an activation link has been sent to your email address.
              </div>
              <div className={styles.main}>
                <p>{activationCode}</p>
                <button
                  className="btn btn-outline-secondary"
                  title="Copy activation url"
                  type="button"
                  onClick={copyToClipboard}
                >
                  <i className={classnames('fas', { 'fa-copy': !copied, 'fa-check': copied })}/>
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default ActivationCode
