import { FC, forwardRef, useMemo } from 'react'
import cn from 'classnames'
import styles from './user-ava.module.scss'

interface IProps {
  user: string
  className?: string
  loading?: boolean
}

export const UserAva: FC<IProps> = forwardRef<HTMLDivElement, IProps>(({ user, className, loading = false }, ref) => {
  const userInitials = useMemo(
    () => [user.split(' ')[0], user.split(' ')[1]].map((name) => name.charAt(0).toUpperCase()),
    [user],
  )

  return (
    <div ref={ref} className={cn(styles.initials, className)}>{!loading ? userInitials : ''}</div>
  )
})
