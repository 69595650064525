import { OktaAuthOptions } from "@okta/okta-auth-js";

const oktaAuth: OktaAuthOptions = {
  issuer: process.env.REACT_APP_OKTA_URL!,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + process.env.REACT_APP_OKTA_AUTH_CALLBACK,
  scopes: ['groups', 'openid', 'email', 'profile'],
}

export default oktaAuth
