import { FC } from 'react'
import { Icon, Tooltip } from '@wmgtech/legato'
import { UserAva } from 'components/UserAva'
import styles from './creator.module.scss'

interface IProps {
  name: string
}

export const Creator: FC<IProps> = ({ name }) => {
  return (
    <Tooltip message={name} colorType="info">
      <UserAva user={name} className={styles.icon} />
    </Tooltip>
  )
}
