import { FC } from 'react'
import { UserAva } from 'components/UserAva'
import { UserInfoProps } from './UserInfo.props'

import style from './UserInfo.module.scss'
import { ConditionRender, SkeletonLine } from "../index";

const UserInfo: FC<UserInfoProps> = ({ user }) => {
  return (
    <div className={style.userInfo}>
      <UserAva user={`${user?.first_name} ${user?.last_name}`} className={style.initials} loading={!user?.first_name}/>

      <ConditionRender condition={!!user?.first_name}
                       secondComponent={<div className={style.skeleton}><SkeletonLine height={18}/></div>}
                       component={<div className={style.user}>{`${user?.first_name} ${user?.last_name}`}</div>}/>

      <ConditionRender condition={!!user?.team?.name}
                       secondComponent={<div className={style.skeletonSmall}><SkeletonLine height={14}/></div>}
                       component={<div className={style.team}>{`${user?.team?.name}`}</div>}/>

      <ConditionRender condition={!!user?.email}
                       secondComponent={<div className={style.skeletonXs}><SkeletonLine height={11}/></div>}
                       component={<div className={style.mail}>{`${user?.email}`}</div>}/>
    </div>
  )
}

export default UserInfo
