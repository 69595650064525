import { createElement, useCallback, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import compose from 'lodash/fp/compose'
import { growl, TTableSortConfig, TTableSortDirection } from '@wmgtech/legato'
import { TPaginatedResponse } from 'shared/types'
import { Toast } from 'components/Toast'
import {
  CREATE_CAMPAIGN,
  DEFAULT_SORT_DIRECTION,
  DEFAULT_SORT_FIELD,
  DELETE_CAMPAIGN,
  GET_CAMPAIGNS,
  SORT_DIRECTION,
  SORT_FIELD_PARAM,
} from '../constants'
import { ICampaign, ICampaignInput, IGetCampaignInput, TFilterKeys } from '../types'
import { filtersParams, pageParams, searchTerm, sortParams } from '../helpers'


export const useCampaign = (limit?: number) => {

  const [searchParams, setSearchParams] = useSearchParams()

  const getCampaignValuable = useMemo<IGetCampaignInput>(
    () => compose(
      pageParams(searchParams),
      sortParams(searchParams),
      searchTerm(searchParams),
      filtersParams(searchParams, 'creator'),
      filtersParams(searchParams, 'reach'),
      filtersParams(searchParams, 'type'),
    )({
      input: {
        limit: limit || 10,
        offset: 0,
        sortOrder: {
          direction: DEFAULT_SORT_DIRECTION,
          field: DEFAULT_SORT_FIELD,
        },
      },
    }),
    [limit, searchParams],
  )

  const { data, loading } = useQuery<{ getCampaigns: TPaginatedResponse<ICampaign> }, IGetCampaignInput>(GET_CAMPAIGNS, {
    variables: getCampaignValuable,
    skip: typeof limit === 'undefined',
  })

  const [createCampaignMutation] = useMutation<{ createCampaign: ICampaign }, { input: ICampaignInput }>(
    CREATE_CAMPAIGN, {
      refetchQueries: [
        {
          query: GET_CAMPAIGNS,
          variables: getCampaignValuable,
        },
      ],
    },
  )

  const [deleteCampaignMutation] = useMutation<{ deleteCampaign: { result: string } }, { id: string }>(
    DELETE_CAMPAIGN, {
      refetchQueries: [
        {
          query: GET_CAMPAIGNS,
          variables: getCampaignValuable,
        },
      ],
    },
  )

  const createCampaign = useCallback<(data: ICampaignInput) => Promise<ICampaign | undefined>>(
    async (input) => {
      const { data } = await createCampaignMutation({
        variables: { input },
      })

      if (data?.createCampaign) {
        growl.success({
          message: createElement(Toast, { message: `Your ID for ${input.artist} | ${input.product} has been generated successfully` }),
          containerStyle: 'tint',
          colorType: 'success',
          icon: 'check',
        }, { autoClose: 3000 })
      }

      return data?.createCampaign
    },
    [createCampaignMutation],
  )

  const deleteCampaign = useCallback(async (id: string) => {
    await deleteCampaignMutation({
      variables: {
        id: id,
      },
    })
    if (data?.getCampaigns?.data) {
      const campaign = data?.getCampaigns?.data?.find((campaign) => campaign.id === id)
      if (campaign) {
        growl.success({
          message: createElement(Toast, { message: `Your ID for ${campaign.artist} | ${campaign.product} has been successfully deleted` }),
          containerStyle: 'tint',
          colorType: 'success',
          icon: 'check',
        }, { autoClose: 3000 })
      }
    }

  }, [deleteCampaignMutation, data])

  const handleSort = useCallback<(field: string) => void>(
    (key: string) => {
      const currentSortField = searchParams.get(SORT_FIELD_PARAM)
      const currentSortDirection = searchParams.get(SORT_DIRECTION)
      if (currentSortField === key && currentSortDirection === 'ASC') {
        searchParams.set(SORT_DIRECTION, 'DESC')
      } else if (currentSortField === key && currentSortDirection === 'DESC') {
        searchParams.set(SORT_DIRECTION, 'ASC')
      } else {
        searchParams.set(SORT_FIELD_PARAM, key)
        searchParams.set(SORT_DIRECTION, DEFAULT_SORT_DIRECTION)
      }
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  const handleFilter = useCallback(
    (name: TFilterKeys, values: ReadonlyArray<string>) => {
      searchParams.delete(name)
      if (values.length) {
        values.forEach(item => {
          searchParams.append(name, item)
        })
      }
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  const sortState = useMemo<TTableSortConfig>(
    () => ({
      key: searchParams.get(SORT_FIELD_PARAM) || DEFAULT_SORT_FIELD,
      direction: (searchParams.get(SORT_DIRECTION) || DEFAULT_SORT_DIRECTION) as TTableSortDirection,
    }),
    [searchParams],
  )

  const getFilters = useCallback(
    <T extends TFilterKeys>(key: T): ReadonlyArray<string> => {
      return searchParams.getAll(key) ?? [];
    },
    [searchParams],
  )

  const filterState = useMemo<Record<TFilterKeys, ReadonlyArray<string>>>(
    () => ({
      creator: getFilters('creator'),
      reach: getFilters('reach'),
      type: getFilters('type'),
    }),
    [getFilters],
  )

  return {
    campaigns: useMemo<TPaginatedResponse<ICampaign>>(
      () => ({
        data: data?.getCampaigns?.data ?? [],
        total: data?.getCampaigns?.total ?? 0,
      }),
      [data],
    ),
    loading,
    createCampaign,
    onSort: handleSort,
    sortState,
    filterState,
    onFilter: handleFilter,
    onDelete: deleteCampaign,
  }
}
