import React, { FC } from 'react'

import { Header } from 'modulesCommon/Header/components'
import { useHeader } from "../services/useHeader";

const HeaderContainer: FC = () => {
  const { isPolicyAccepted, user, userLoading } = useHeader()

  return isPolicyAccepted && !userLoading ? (
      <Header user={user}/>
  ) : null
}

export default HeaderContainer
