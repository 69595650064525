import React, { FC, useEffect, useState } from 'react'
import { Button, Icon, Tag } from "@wmgtech/legato";
import styles from './Bubbles.module.scss'
import { Bubble } from 'components'
import about from '../../assets/about/about.png'
import { NavLink } from "react-router-dom";

const Bubbles: FC = () => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setLoaded(true), 100)
    return () => {
      clearTimeout(timeout)
      setLoaded(false)
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <Bubble loaded={loaded} color="purple" type="big" text={
          <>
            <div>
              ACID creates uniquely generated IDs for your ad campaigns.
            </div>
            <div>
              This 6 digit id links your campaign to the correct artist and product (from official WMG internal data
              sources) and to the creator (you) and your team.
            </div>
          </>
        }/>
        <Bubble loaded={loaded} color="purple" type="small" text={<>
          <Icon name="link-simple" className={styles.icons}/>
          <div>
            No more manually looking up ISRCs! And you can rest assured knowing the campaign data is accurate.
          </div>
        </>}/>
        <Bubble loaded={loaded} color="gray" type="big" text={<>
          <Tag text="International" removable={false} className={styles.tag}/>
          ACID is designed for both domestic and international campaigns. It works for all ad-buying teams in every
          market.
          <Tag text="Domestic" removable={false} className={styles.grayTag} colorType={'dark'}/>
        </>}/>
      </div>
      <div className={styles.mid}>
        <Bubble loaded={loaded} color="gray" type="middle" text={<>
          <Icon name="history" className={styles.icons}/>
          <div>
            Generated campaigns are cataloged, so admins can go back and keep track of who created which campaign on a
            given date.
          </div>
        </>}/>
        <div className={styles.img}>
          <img alt={'acid-presentation'} className={styles.image} src={about} style={{ transform: `scale(${loaded ? 1 : 0})`, transition: `.4s .1s ease-out` }}/>
        </div>
        <Bubble loaded={loaded} color="purple" type="middle" text={<>
          <Icon name="paintbrush-pencil" className={styles.icons}/>
          <div>
            The ID generator has a field for Additional Information, allowing users to add customized details for better
            organization and internal collaboration.
          </div>
        </>}/>
      </div>
      <div className={styles.bot}>
        <Bubble loaded={loaded} color="purple" type="huge" offsetX={-36} offsetY={-8} customStyle={{ padding: "35px 57px 57px" }} text={<>
          <Icon name="envelope" className={styles.icons} style={{ marginBottom: 20 }}/>
          <div>
            <div>
              Please, contact your local marketing manager for ACID account access.
            </div>
            <div className={styles.paragraph}>
              For additional information or how to get your team onboarded please see our <NavLink to={`/faq`}
                                                                                                   className={styles.link}>FAQ
              page</NavLink> or reach out to<br/>
              <a href="mailto:acid-support@warnermusic.com" className={styles.link}> acid-support@warnermusic.com</a>.
            </div>
          </div>
        </>}/>
        <Bubble loaded={loaded} color="gray" type="huge" offsetX={43} offsetY={-9} text={<>
          <Button icon="plus" containerStyle='outline' colorType="info" className={styles.btn}>New User</Button>
          <div>
            ACID features a decentralized user management system allowing teams to add users without the hassle of using
            Okta or Service Now.
          </div>
        </>}/>
      </div>
    </div>
  )
}

export default Bubbles
