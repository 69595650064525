import { useQuery } from '@apollo/client'
import { EMAIL_LIST } from 'modules/ExportUsers/constants'

export const useExportUsersList = () => {
  const { data, loading } = useQuery(EMAIL_LIST)

  return {
    usersList: data?.exportEmailList,
    loading,
  }
}
