import { gql } from '@apollo/client'

const USER_FIELD = gql`
  fragment UserField on User {
    id
    email
    first_name
    last_name
    username
    is_active
    is_external
    label {
      id
      alias
      name
    }
    team {
      id
      alias
      name
    }
    role {
      role_id
    }
  }
`


export const GET_USERS = gql`
  ${USER_FIELD}
  query {
    getUsers {
      ...UserField
    }
  }
`

export const LIST_USERS = gql`
  ${USER_FIELD}
  query ($input: Userlist){
    listUsers(input: $input) {
      data {
        ...UserField,
      }
      total
    }
  }
`

export const GET_CREATOR = gql`
  query {
    getUsers {
      id
      first_name
      last_name
    }
  }
`

export const GET_INTERNAL_DOMAINS = gql`
    query {
        getInternalDomains
    }
`

export const CREATE_USER = gql`
  mutation($input: UserCreate) {
    createUser(input: $input) {
      email
      first_name
      id
      team {
          name
      }
      label {
          name
      }
    }
  }
`


export const DELETE_USER = gql`
  mutation($id: String!) {
    deleteUser(id: $id) {
      id
    }
  }
`

export const RESTORE_USER = gql`
  mutation($id: String!) {
    restoreUser(id: $id) {
      id
    }
  }
`

export const UPDATE_USER = gql`
  ${USER_FIELD}
  mutation($input: UserUpdate) {
    updateUser(input: $input) {
      ...UserField
    }
  }
`



