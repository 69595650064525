import React, { FC, useEffect } from 'react'
import { LoginCallback } from '@okta/okta-react'

import { isFirstLoginVar } from 'modulesCommon/store'
import { ErrorPageContent } from "../../../components";

const LoginCallbackContainer: FC = () => {
  useEffect(() => {
    isFirstLoginVar(true)
  }, [])

  return <LoginCallback
    errorComponent={({ error }) => (
      <ErrorPageContent
        title={'Okta Error'}
        description={error.message}
        children={<div>{error.name}</div>}
      />
    )}
  />
}

export default LoginCallbackContainer
