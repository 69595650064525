import { FC } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { GrowlContainer } from '@wmgtech/legato'

import { GenerateCampaignButton } from 'modules/Campaign/containers'
import { Menu, SkeletonLine } from 'components'
import { Logo } from 'components/Logo'

import { IHeaderProps } from './Header.props'
import styles from './Header.module.scss'

const Header: FC<IHeaderProps> = ({ user }) => {
  const isLoading = false

  return (
    <header role={'heading'} className={styles.header}>
      <div className={cn('inner', styles.wrapper)}>
        <div className={styles.heading}>
          <Link to="/">
            <Logo isLoading={isLoading}/>
          </Link>
          <div>
            <div className={styles.project}>ACID | Warner Music Group</div>
            {
              !!user.first_name ? (
                <div className={styles.user}>{user.first_name} {user.last_name} | {user?.team?.name || ''}</div>
              ) : (
                <SkeletonLine/>
              )
            }
          </div>
        </div>
        <div className={styles.menu}>
          <GenerateCampaignButton/>
          <Menu user={user}/>
        </div>
      </div>
      <GrowlContainer/>
    </header>
  )
}

export default Header
