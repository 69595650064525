import { createElement, useCallback, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { growl } from '@wmgtech/legato'

import { ICategory } from 'modules/Admin'
import { CATEGORIES_LIST, CREATE_CATEGORY, DELETE_CATEGORY, UPDATE_CATEGORY } from '../constants'
import { Toast } from 'components/Toast'

interface ICategoryInput {
  name: string
}

interface ICategoryUpdateInput {
  id: string
  name: string
}

export const useCategories = () => {
  const { data: categories, loading } = useQuery<{ getCategories: [ICategory] }>(CATEGORIES_LIST)

  const [createCategoryMutation] = useMutation<{ createCategory: ICategory }, { input: ICategoryInput }>(CREATE_CATEGORY,{
    update(cache) {
      cache.modify({
        fields: {
          getCategories() {},
        },
      })
    },
  })

  const [updateCategoryMutation] = useMutation<{ updateCategory: ICategory }, { input: ICategoryUpdateInput }>(UPDATE_CATEGORY,{
    update(cache) {
      cache.modify({
        fields: {
          getCategories() {},
        },
      })
    },
  })

  const [deleteCategoryMutation] = useMutation<{ deleteCategory: { data: any } }>(DELETE_CATEGORY, {
    update(cache) {
      cache.modify({
        fields: {
          getCategories() {},
        },
      })
    },
  })

  const createCategory = useCallback(
    async ( name : string) => {
      await createCategoryMutation({
        variables: {
          input: { name },
        },
      })
      growl.success({
        message: createElement(Toast, { message: `${name} has been created successfully` }),
        containerStyle: 'tint',
        colorType: 'success',
        icon: 'check',
      }, { autoClose: 3000 })
    },
    [createCategoryMutation],
  )

  const updateCategory = useCallback(
    async (id: string, name: string) => {
      await updateCategoryMutation({
        variables: {
          input: { id, name },
        },
      })
      growl.success({
        message: createElement(Toast, { message: `${name} has been saved successfully` }),
        containerStyle: 'tint',
        colorType: 'success',
        icon: 'check',
      }, { autoClose: 3000 })
    },
    [updateCategoryMutation],
  )

  const deleteCategory = useCallback<(id: string) => void>(
    async (id) => {
      await deleteCategoryMutation({
        variables: {
          id,
        },
      })

      if (categories?.getCategories) {
        const category = categories?.getCategories.find((cat) => cat.id === id)
        if (category) {
          growl.success({
            message: createElement(Toast, { message: `Category ${category.name} has been successfully deleted` }),
            containerStyle: 'tint',
            colorType: 'success',
            icon: 'check',
          }, { autoClose: 3000 })
        }
      }

    },
    [deleteCategoryMutation, categories],
  )

  const categoryHash = useMemo<{[id: string]: string }>(() => categories?.getCategories?.reduce((acc, item) => ({
    ...acc,
    [item.id]: item.name,
  }), {}) ?? {}, [categories?.getCategories])

  const getCategoryById = useCallback((id: string) => categoryHash[id], [categoryHash])

  return {
    categories: categories?.getCategories,
    loading,
    createCategory,
    updateCategory,
    deleteCategory,
    getCategoryById,
  }
}
