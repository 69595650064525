import { FC } from 'react'
import { TCampaignType } from 'modules/Campaign'
import styles from './product-item.module.scss'

interface IProps {
  artistName: string
  displayTitle: string
  type: TCampaignType
}

export const SearchProductItem: FC<IProps> = ({ displayTitle, type, artistName }) => {
  return (
    <div className={styles.wrapper}>
      <dl className={styles.container}>
        <dt>{displayTitle}</dt>
        <dd>{artistName}</dd>
        <dd>{type}</dd>
      </dl>
    </div>
  )
}