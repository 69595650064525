import { ReactElement, useCallback, useMemo } from 'react'
import { Button, Modal } from '@wmgtech/legato'
import { useWatch } from 'react-hook-form'

import { useAdmin } from 'modules/Admin'

import { FormBuilder } from '../FormBuilder'

import { IModalFormProps } from './modal-form.props'
import styles from './modal-form.module.scss'

export function ModalForm<T extends Record<string, any>>({ onSubmit, onChangeForm }: IModalFormProps<T>): ReactElement {
  const id = useWatch({ name: 'id' })

  const { entity, setModalStatus, isModalOpen, handleSubmit, formState } = useAdmin<T>()

  const handleClose = useCallback(() => setModalStatus(false), [setModalStatus])

  const title = useMemo(
    () => `${typeof id === 'undefined' ? 'New' : 'Edit'} ${entity.name}`,
    [id, entity.name],
  )

  const submitWrapper = useCallback<(data: T) => Promise<unknown>>(
    async (data: T) => {
      await onSubmit({ ...data, ...(data.email && { email: data.email.trim() }) })
      handleClose()
    },
    [handleClose, onSubmit],
  )

  return (
    <Modal
      title={title}
      onClose={handleClose}
      isOpen={isModalOpen}
      shouldCloseOnOverlayClick={false}
    >
      <form onSubmit={handleSubmit(submitWrapper)} autoComplete="off">
        <FormBuilder fields={entity.fields} isEdit={typeof id !== 'undefined'} onChangeForm={onChangeForm}/>
        <div className={styles.btns}>
          <Button containerStyle='outline' onClick={handleClose} colorType="info">Cancel</Button>
          <Button
            colorType="primary"
            htmlType='submit'
            disabled={formState.isSubmitting}
          >{typeof id === 'undefined' ? 'Create' : 'Save'}</Button>
        </div>
      </form>
    </Modal>
  )
}
