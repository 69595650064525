import { useCallback } from 'react'
import { ICampaign, ICampaignInput, IGenerateCampaignForm, IPlaylist, IProduct } from '../types'
import { useFormContext } from 'react-hook-form'
import { getCampaignName } from '../helpers'

interface Props {
  createCampaign(data: ICampaignInput): Promise<ICampaign | undefined>

  setCampaign(data: {
    id: string
    name: string
    artist: string
    product: string
  }): void
}

const getEanOrUpc = (product: IProduct): string[] => {
  if (product.productType === 'Album') {
    return product.upc?.length && product.upc.length > 0 ? product.upc : (product.ean?.length && product.ean.length > 0 ? product.ean : [])
  }

  return [...product.isrcList]
}

export const useGenerateCampaignForm = ({ createCampaign, setCampaign }: Props) => {
  const { handleSubmit, formState, watch, reset } = useFormContext<IGenerateCampaignForm>()
  const isDisabled = !formState.isValid || formState.isSubmitting
  const category = watch('productCategory')

  const getProduct = useCallback((playlist: IPlaylist[]) => playlist.map(({ PLAYLIST_NAME }) => PLAYLIST_NAME).join(' | '), [])

  const onSubmit = useCallback(async ({ artist, product, playlist, reach, info, category }: IGenerateCampaignForm) => {
    const isrcs = product ? getEanOrUpc(product) : []
    const siblingIDs = product?.productType === 'Single' ? (product.siblingIdList ? product.siblingIdList : []) : []
    const playlists = playlist?.map(({ __typename, ...rest }) => ({ ...rest })) ?? []

    return createCampaign({
      reach,
      artist: artist?.artistDisplayTitle ?? '',
      product: playlist.length ? getProduct(playlist) : product?.displayTitle ?? '',
      type: product?.productType ?? '',
      group_id: siblingIDs,
      ...(artist?.artistId && { artistId: artist?.artistId }),
      ...(product?.familyId && { family_id: product.familyId }),
      playlists,
      category,
      isrcs,
      info,
    })
      .then((campaign: ICampaign | undefined) => {
        if (campaign) {
          setCampaign({
            id: campaign.id,
            name: getCampaignName(campaign),
            artist: campaign.artist,
            product: campaign.product,
          })
        }
      })
    },
  [setCampaign, createCampaign, getProduct],
  )

  const onResetForm = useCallback((productCategory?: string) => {
    reset({
      productCategory,
      category: '',
      playlist: [],
      reach: 'International',
      artist: null,
      product: undefined,
      info: '',
    })
  }, [reset])

  return {
    category,
    isDisabled,
    onSubmit,
    handleSubmit,
    onResetForm,
  }
}
