import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useOktaAuth } from '@okta/okta-react'

import { currentUserVar } from 'modulesCommon/store'
import { GET_USER_BY_EMAIL } from 'modulesCommon/Header/graphql';

export const useHeader = () => {
  const { authState, oktaAuth } = useOktaAuth()

  const {
    data: userData,
    loading,
    error,
  } = useQuery(GET_USER_BY_EMAIL, {
    skip: !authState?.idToken?.claims.email,
    variables: { email: authState?.idToken?.claims.email || '' },
    // onError(err) {
    //   // @ts-ignore
    //   // eslint-disable-next-line no-console
    //   if (err.networkError && 'statusCode' in err.networkError && err.networkError.statusCode === 401) {
    //     oktaAuth.signOut()
    //   }
    // },
  })
  useEffect(() => {
    if(error) throw error
    if (userData) {
      currentUserVar(userData?.findUserByEmail)
    }
  }, [error, userData])


  return {
    user: userData?.findUserByEmail || {},
    userLoading: loading,
    isPolicyAccepted: userData?.findUserByEmail?.terms_accepted,
  }
}


