import { FC, useRef, useEffect } from 'react'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'

import animationData from 'assets/animatedIcons/animatedLogo.json'

import { ILogoProps } from './Logo.props'

import styles from './Logo.module.scss'

export const Logo: FC<ILogoProps> = ({ isLoading }) => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)

  useEffect(() => {
    if (isLoading) {
      lottieRef.current?.goToAndPlay(0, true)
    } else {
      lottieRef.current?.goToAndStop(100, true)
    }
  }, [isLoading])

  return <Lottie lottieRef={lottieRef} animationData={animationData} loop={true} className={styles.logo} />
}
