import { gql, useMutation } from '@apollo/client'

export const ASSIGN_USER_OKTA_GROUP = gql`
  mutation($email: String!) {
    assignUserOktaGroup(email: $email) {
      result
    }
  }
`

export const useAssignUserOktaGroup = () => {
  const [mutate, { data, loading, error }] = useMutation(ASSIGN_USER_OKTA_GROUP)

  return { mutate, data, loading, error }
}
