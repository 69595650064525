// TODO: Uncomment after notifications

// import { UrlInfo } from "aws-appsync-subscription-link/lib/types";

const subsAWS = (jwtToken: string): any => ({
  url: process.env.REACT_APP_SUBSCRIPTION_URL as string,
  region: process.env.REACT_APP_AWS_REGION as string,
  auth: {
    type: 'OPENID_CONNECT',
    jwtToken,
  },
})

export default subsAWS
