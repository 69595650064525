import { gql } from '@apollo/client'

export const CATEGORIES_LIST = gql`
    query {
        getCategories {
            id
            name
        }
    }
`

export const CREATE_CATEGORY = gql`
    mutation($input: CategoryInput) { 
        createCategory(input: $input) {
            id
            name
        }
    }
`

export const UPDATE_CATEGORY = gql`
    mutation($input: CategoryUpdateInput) { 
        updateCategory(input: $input) {
            id
            name
        }
    }
`

export const DELETE_CATEGORY = gql`
    mutation($id: String) {
        deleteCategory(id: $id) {
            result,
        }
    }
`
