import React, { FC, useCallback, useState } from 'react'
import { useController } from 'react-hook-form'
import { InputGroup, AsyncSelect, Badge } from '@wmgtech/legato'
import { IFieldError, validationAdapter } from "components/Fields"
import { useSearchPlaylist } from "../../services"
import { SearchItem } from "./SearchItem"
import compact from "lodash/fp/compact"
import uniqBy from 'lodash/uniqBy'
import { IPlaylist } from "../../types"
import styles from './form.module.scss'

export const FormSearchPlaylist: FC = () => {
  const [selectedValue, setValue] = useState<string | undefined>(undefined)
  const NAME = 'playlist'
  const { field: { onChange, ref, value, ...rest }, formState: { errors } } = useController({ name: NAME })

  const { options, loading, getPlaylist } = useSearchPlaylist()

  const onSelect = useCallback((data: IPlaylist) => {
    if (data !== undefined) {
      setValue(undefined)
      onChange(uniqBy([...value, data], 'PLAYLIST_ID'))
    }
  }, [setValue, onChange, value])

  const onSearch = useCallback((term: string) => {
    setValue(term)
    getPlaylist(term)
  }, [setValue, getPlaylist])

  const validation = useCallback((error?: IFieldError) => ({
   required: true,
   pending: loading,
   ...validationAdapter(error),
  }),[loading])

  const onRemovePlaylistBadge = useCallback((el: string) => (event: React.MouseEvent<HTMLDivElement>) => {
    if ((event.target as HTMLDivElement).classList.contains('lgt-icon')) {
        onChange(value.filter((i: IPlaylist) => i.PLAYLIST_ID !== el))
    }
  }, [onChange, value])

  return (
    <>
      <InputGroup label="Playlist name" validation={validation(errors[NAME] as IFieldError)}>
        {!!value.length && (
          <div className={styles.badgeList}>
            {value.map((v: IPlaylist) =>
              <div onClick={onRemovePlaylistBadge(v.PLAYLIST_ID)} key={v.PLAYLIST_ID}>
                <Badge
                  size='sm'
                  text={v.PLAYLIST_NAME}
                  icon
                  iconName="xmark"
                  className={styles.badge}
                  containerStyle="solid"
                  colorType="primary"
                />
            </div>)}
          </div>
        )}
        <AsyncSelect
          value={selectedValue}
          onSelect={onSelect}
          placeholder="Playlist name"
          onSearch={onSearch}
          list={options}
          isLoading={loading}
          getKey={({ PLAYLIST_ID }) => PLAYLIST_ID}
          getTitle={() => ''}
          getElement={(playlist) => (
            <SearchItem
              title={playlist.PLAYLIST_NAME}
              subtitle={compact([playlist.ACCOUNT, playlist.OWNER_PROFILE, playlist.PLAYLIST_ID]).join(' | ')}
            />)
          }
          {...rest}
        />
      </InputGroup>
    </>
  )
}
