import React, { FC, useCallback } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Button, Modal } from '@wmgtech/legato'

import { useInfoMessage } from 'modules/LoginMessage/services/useLoginMessage'
import { isFirstLoginVar } from 'modulesCommon/store'

import styles from './login-message-dialog.module.scss'

export const LoginMessageDialog: FC = () => {
    const isFirstLogin = useReactiveVar(isFirstLoginVar)
    const { infoMessage } = useInfoMessage()

    const onOK = useCallback(() => {
        isFirstLoginVar(false)
    }, [])

    return (
        <Modal
            title=''
            onClose={onOK}
            isOpen={infoMessage?.active && isFirstLogin}
            footer={(
                <div className={styles.content}>
                    <Button
                        containerStyle="solid"
                        colorType="secondary-black"
                        label="OK"
                        size="sm"
                        className={styles.btn}
                        onClick={onOK}
                    />
                </div>
            )}
        >
            <div dangerouslySetInnerHTML={{ __html: `${infoMessage?.message}` }} />
        </Modal>
    )
}
