import { FC } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useOktaAuth } from "@okta/okta-react"
import { Outlet } from 'react-router-dom'

import { Header } from 'modulesCommon/Header/containers'
import { useScrollToTop } from 'shared/hooks'
import { LoginMessageDialog } from 'modules/LoginMessage/components'

const MainLayoutContainer: FC = () => {
  useScrollToTop()
  const { authState } = useOktaAuth()

  return (
    <HelmetProvider>
      {
        !!authState?.idToken && (
          <>
            <Header />
            <LoginMessageDialog />
          </>
        )
      }
      <Outlet />
    </HelmetProvider>
  )
}

export default MainLayoutContainer
