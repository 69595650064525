import { FC } from 'react'
import { CampaignTable, useCampaign } from 'modules/Campaign'
import { ConditionRender, ScrollToTopButton, SkeletonTable } from 'components';
import { SearchField } from 'components/Fields'
import { Pagination } from 'components/Pagination'
import { useConfirmation } from 'components/Confirmation'

const PAGE_SIZE = 10

export const HomePage: FC = () => {

  const { campaigns, loading, sortState, onSort, filterState, onFilter, onDelete } = useCampaign(PAGE_SIZE)

  const { onConfirm, element } = useConfirmation(
    onDelete,
    (message) => (<>Are you sure you want to delete campaign <b>{message}</b></>),
  )

  return (
    <main className='main'>
      <section className='inner'>
        <h1 className="title">Recently Generated Campaign Names</h1>
        <SearchField/>

        <ConditionRender condition={!loading} component={
          <CampaignTable
            dataReader={campaigns.data}
            sortState={sortState}
            onSort={onSort}
            filter={filterState}
            onFilter={onFilter}
            onDelete={onConfirm}
          />
        } secondComponent={<SkeletonTable lines={10}/>}
        />

        <Pagination total={campaigns.total} pageSize={PAGE_SIZE}/>
        <ScrollToTopButton/>
      </section>
      {
        element
      }
    </main>
  )
}
