import { useCallback, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import debounce from 'lodash/debounce'
import { SEARCH_PLAYLIST } from '../constants'
import { IArtistSearchInput, IPlaylist } from '../types'

type TPaginatedResponse<T> = {
    items: ReadonlyArray<T>
    total: number
}

type TArgument<T> = {
    input: T
}

export const useSearchPlaylist = () => {
  const [getPlaylistQuery, { loading, data }] = useLazyQuery<{ searchPlaylists: TPaginatedResponse<IPlaylist> }, TArgument<IArtistSearchInput>>(SEARCH_PLAYLIST)

  const getPlaylist = useCallback(async (searchTerm: string) => {
    if (typeof searchTerm === 'undefined' || searchTerm.length < 3) return Promise.resolve([])
      try {
        const { data } = await getPlaylistQuery({
          variables: {
            input: { searchTerm },
          },
        })
        return data?.searchPlaylists?.items || []
      } catch (err) {
        console.warn(err)
      }
    },
 [getPlaylistQuery])

  const getPlaylistDebounce = useRef(debounce(getPlaylist, 500))

  return {
    loading,
    options: data?.searchPlaylists?.items,
    getPlaylist: getPlaylistDebounce.current,
  }
}
