import { useMemo, useRef, useCallback } from 'react'
import { FilterSelect, Icon } from '@wmgtech/legato'
import { useQuery } from '@apollo/client'
import { GET_CREATOR } from 'modules/Users'
import { UserAva } from 'components/UserAva'

import styles from './creator-option.module.scss'

interface ICreator {
  id: string
  first_name: string
  last_name: string
}

interface IOption {
  id: string
  title: string
}

const getKey = ({ id }: IOption) => id

export const useCreatorFilter = (filter: ReadonlyArray<string>, onFilter: (list: ReadonlyArray<string>) => void) => {

  const refClose = useRef<() => void>()

  const { data } = useQuery<{getUsers: ReadonlyArray<ICreator>}>(GET_CREATOR)

  const creators = useMemo(
    () => (data?.getUsers ?? []).map(c => ({ id: c.id, title: [c.first_name, c.last_name].join(' ') })),
    [data],
  )

  const creatorByID = useMemo(
    () => creators.reduce<Record<string, IOption>>((a, v) => ({ ...a, [v.id]: v }), {}),
    [creators],
  )

  const enrichedFilter = useMemo(
    () => filter.map(id => creatorByID[id]),
    [creatorByID, filter],
  )

  const handleFilter = useCallback(
    (list: ReadonlyArray<IOption>) => {
      onFilter(list.map(getKey));
      if (typeof refClose.current === 'function') refClose.current()
    },
    [onFilter],
  )

  const handleClear = useCallback(
    () => {
      onFilter([]);
      if (typeof refClose.current === 'function') refClose.current()
    },
    [onFilter],
  )

  const renderOption = useCallback(
    ({ title }: IOption) => (
      <div className={styles.option}>
        <UserAva className={styles.icon} user={title} />
        {title}
      </div>
    ),
    [],
  )

  const renderFilter = useCallback(
    (cb: () => void) => {
      refClose.current = cb
      return (
        <FilterSelect<IOption>
          list={creators}
          value={enrichedFilter}
          onClearButtonClick={handleClear}
          onFilterButtonClick={handleFilter}
          getKey={getKey}
          getTitle={({ title }) => title}
          getElement={renderOption}
        />
      )
    },
    [creators, enrichedFilter, handleClear, handleFilter, renderOption],
  )

  return {
    isFilterApplied: Boolean(filter.length),
    renderFilter,
  }
}