import { gql } from '@apollo/client'

export const GET_USER_BY_EMAIL = gql`
    query ($email: String!) {
        findUserByEmail(email: $email) {
            id
            email
            first_name
            last_name
            label_id
            is_external
            terms_accepted
            team {
                id
                alias
                label_id
                name
            }
            role {
                role_id
            }
        }
    }
`
