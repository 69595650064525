import React, { FC, useCallback } from 'react'
import { useController } from 'react-hook-form'
import { Select, InputGroup } from '@wmgtech/legato'
import { useCategories } from 'modules/Categories/services'

export const FormCategorySelect: FC = () => {
  const NAME = 'category'
  const { field: { onChange, ref, ...rest }, formState: { errors } } = useController({ name: NAME })
  const { categories } = useCategories()

  const handleChange = useCallback(({ id }: { id: string; }) => { onChange(id) }, [onChange])

  return (
    <InputGroup label="Category" validation={{ required: true }}>
      <Select
        list={categories ?? []}
        onSelect={handleChange}
        placeholder="Select Category"
        getKey={(el) => el.id}
        getTitle={(el) => el.name}
        {...rest}
      />
    </InputGroup>
  )
}
