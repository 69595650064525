import { createElement, useCallback, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_TEAM,
  DEFAULT_SORT_DIRECTION,
  DEFAULT_SORT_FIELD,
  SORT_DIRECTION,
  SORT_FIELD_PARAM,
  UPDATE_TEAM,
} from '../constants'
import { ITeam, TEAMS_LIST } from 'modules/Admin'
import { useSearchParams } from "react-router-dom";
import compose from "lodash/fp/compose";
import { ITeamListInput } from "../types";
import { pageParams, searchTerm, sortParams } from "../helpers";
import { TPaginatedResponse } from "../../../shared/types";
import { growl, TTableSortConfig, TTableSortDirection } from "@wmgtech/legato";
import { Toast } from "../../../components/Toast";

export const useTeams = (limit?: number) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getTeamListVariables = useMemo<ITeamListInput>(
    () => compose(
      pageParams(searchParams),
      sortParams(searchParams),
      searchTerm(searchParams),
    )({
      input: {
        limit: limit || 10,
        offset: 0,
        sortOrder: {
          direction: DEFAULT_SORT_DIRECTION,
          field: DEFAULT_SORT_FIELD,
        },
      },
    }),
    [limit, searchParams],
  )

  const { data: teams, loading } = useQuery<{ getTeams: TPaginatedResponse<ITeam> }, ITeamListInput>(TEAMS_LIST, {
    variables: getTeamListVariables,
    skip: typeof limit === 'undefined',
  })

  const handleSort = useCallback<(field: string) => void>(
    (key: string) => {
      const currentSortField = searchParams.get(SORT_FIELD_PARAM)
      const currentSortDirection = searchParams.get(SORT_DIRECTION)
      if (currentSortField === key && currentSortDirection === 'ASC') {
        searchParams.set(SORT_DIRECTION, 'DESC')
      } else if (currentSortField === key && currentSortDirection === 'DESC') {
        searchParams.set(SORT_DIRECTION, 'ASC')
      } else {
        searchParams.set(SORT_FIELD_PARAM, key)
        searchParams.set(SORT_DIRECTION, DEFAULT_SORT_DIRECTION)
      }
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  const sortState = useMemo<TTableSortConfig>(
    () => ({
      key: searchParams.get(SORT_FIELD_PARAM) || DEFAULT_SORT_FIELD,
      direction: (searchParams.get(SORT_DIRECTION) || DEFAULT_SORT_DIRECTION) as TTableSortDirection,
    }),
    [searchParams],
  )

  const [createTeamMutation] = useMutation<{ createTeam: ITeam }, { input: Omit<ITeam, 'id' | 'alias' | 'label'> }>(CREATE_TEAM, {
    update(cache) {
      cache.modify({
        fields: {
          getTeams() {
          },
        },
      })
    },
  })
  const [updateTeamMutation] = useMutation<{ updateTeam: ITeam }, { input: Omit<ITeam, 'alias' | 'label' | 'label_id'> }>(UPDATE_TEAM, {
    update(cache) {
      cache.modify({
        fields: {
          getTeams() {
          },
        },
      })
    },
  })

  const createTeam = useCallback(
    async ({ name, label_id }: Omit<ITeam, 'id' | 'alias' | 'label'>) => {
      await createTeamMutation({
        variables: {
          input: { name, label_id },
        },
      })
      growl.success({
        message: createElement(Toast, { message: `${name} has been created successfully` }),
        containerStyle: 'tint',
        colorType: 'success',
        icon: 'check',
      }, { autoClose: 3000 })
    },
    [createTeamMutation],
  )

  const updateTeam = useCallback(
    async ({ name, id }: Omit<ITeam, 'alias' | 'label' | 'label_id'>) => {
      await updateTeamMutation({
        variables: {
          input: { name, id },
        },
      })
      growl.success({
        message: createElement(Toast, { message: `${name} has been saved successfully` }),
        containerStyle: 'tint',
        colorType: 'success',
        icon: 'check',
      }, { autoClose: 3000 })
    },
    [updateTeamMutation],
  )

  return {
    teams: useMemo<TPaginatedResponse<ITeam>>(
      () => ({
        data: teams?.getTeams?.data ?? [],
        total: teams?.getTeams?.total ?? 0,
      }),
      [teams],
    ),
    loading,
    onSort: handleSort,
    sortState,
    updateTeam,
    createTeam,
  }
}
