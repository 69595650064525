import React, { FC } from 'react'
import { Button, Dropdown, Icon } from '@wmgtech/legato'

import { downloadCsv } from 'shared'
import { useExportUsersList } from '../services/useExportUsers'

import styles from './ExportUsersList.module.scss'

export const ExportUsersList: FC = () => {
  const { usersList } = useExportUsersList()

  const exportUsersListToCsv = async () => {
    const usersEmailsList = usersList.map((e: {email: string}) => e.email)
    downloadCsv(usersEmailsList.join('\n'), 'Users.csv')
  }

  return (
    <Dropdown placement="bottomLeft" list={[
      {
        key: '1',
        title: <a onClick={exportUsersListToCsv}>
                Export Users to CSV <Icon name="download"/>
              </a>,
      },
      ]} >
        <Button 
          className={styles.button} 
          containerStyle='link' 
          colorType="secondary-black" 
          icon="ellipsis"/>
    </Dropdown>
  )
}
