import { FC, useCallback, useMemo, useState, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Overlay, Button } from '@wmgtech/legato'
import { useForm, FormProvider } from 'react-hook-form'
import { CopyField } from 'components/CopyField'
import { currentUserVar } from 'modulesCommon/store'

import { isRequired } from 'components/Fields'
import { GenerateCampaignForm } from '../../components/Generate'
import styles from './generate-campaign-button.module.scss'

interface ICampaignSummary {
  id: string
  name: string
  artist: string
  product: string
}

export const GenerateCampaignButton: FC = () => {

  const [isOpen, setStatus] = useState<boolean>(false)
  const [campaign, setCampaign] = useState<ICampaignSummary | undefined>()
  const currentUser = useReactiveVar(currentUserVar)

  const methods = useForm({
    defaultValues: {
      productCategory: 'Release',
      category: '',
      playlist: [],
      reach: 'International',
      artist: null,
      product: '',
      type: 'Single',
      info: '',
      isExternal: !!currentUser?.is_external,
    },
    reValidateMode: 'onChange',
    resolver: (values) => ({
      values,
      errors: ['artist', 'playlist', 'category'].reduce((a, v) => {
        // if (!values.isExternal && v === 'product') return a
        if (
            values.productCategory !== 'Release' && v === 'artist' ||
            values.productCategory !== 'Playlist' && v === 'playlist' ||
            values.productCategory !== 'Other' && v === 'category'
        ) return a
        return isRequired(values, v)(a)
      }, {}),
    }),
  })

  const trigger = useMemo(
    () => (
      <Button
        label="Generate Campaign"
        colorType="primary"
        size="lg"
        icon="add"
      />
    ),
    [],
  )

  const handleOpen = useCallback(
    (state: boolean) => {
      if (state) {
        methods.reset()
        setCampaign(undefined)
      }
      setStatus(state)
    },
    [methods],
  )

  const handleRepeat = useCallback(
    () => {
      methods.reset()
      setCampaign(undefined)
    },
    [methods],
  )

  useEffect(() => {
    if (currentUser?.email) {
      methods.reset({
        ...methods.getValues(),
        isExternal: !!currentUser?.is_external,
      })
    }
  }, [currentUser, methods])

  return (
    <Overlay
      placement="bottom"
      showFang
      trigger={trigger}
      open={isOpen}
      onOpenChange={handleOpen}
    >
      {
        campaign ? (
          <div className={styles.container}>
            <h2 className={styles.title}>Success!</h2>
            <div className={styles.fields}>
              <p>You ID for <b>{[campaign.artist, campaign.product].join(' | ')}</b> has been generated successfully!</p>
              <CopyField label='Campaign Name' text={campaign.name} />
              <CopyField label='Unique ID' text={`ACID:${campaign.id}`} />
            </div>
            <div className={styles.btns}>
              <Button
                icon="repeat"
                containerStyle="outline"
                colorType="info"
                onClick={handleRepeat}
              >Generate Another</Button>
              <Button
                onClick={() => handleOpen(false)}
              >Done</Button>
            </div>
          </div>
        ) : (
          <div className={styles.container}>
            <h2 className={styles.title}>Generate Campaign</h2>
            <FormProvider {...methods}>
              <GenerateCampaignForm
                setCampaign={setCampaign}
                handleClose={() => setStatus(false)}
              />
            </FormProvider>
          </div>
        )
      }
    </Overlay>
  )
}
