import { IEntityConfig } from "../../../modules/Admin";
import { useReactiveVar } from "@apollo/client";
import { currentUserVar } from "../../../modulesCommon/store";

export const useApplyPermissions = (entity: IEntityConfig) => {
  const currentUser = useReactiveVar(currentUserVar)
    const labelField = entity.fields.find((field) => field.name === 'label')
    if (!labelField) {
      return entity
    }

  if (currentUser?.role?.role_id === 'admin') {
    labelField.disabled = false
  } else {
    labelField.disabled = true
  }

  return entity
}
