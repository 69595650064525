import React, { FC, useMemo } from 'react'
import { SearchField } from "../../../../components/Fields";
import { ColumnsType, ITableProps, Table } from "@wmgtech/legato";
import styles from "../../../Users/components/Users/user.module.scss";
import { ConditionRender, SkeletonTable } from "../../../../components";
import { useSearchLogs } from "../../services";
import { ILogSearch } from "../../types";
import { Pagination } from "../../../../components/Pagination";
import { SearchResultsTable } from "./SearchResultsTable";

type TSearchLogProps = {}

const PAGE_SIZE = 10
export const SearchLog: FC<TSearchLogProps> = (props) => {

  const { searchLogs, loading } = useSearchLogs(PAGE_SIZE)


  const columns = useMemo<ColumnsType<ILogSearch>[]>(
    () => [
      {
        dataIndex:'created_at',
        title: 'Date',
        key: 'date',
        render: (date) => new Date(date).toLocaleString(),
      },
      {
        dataIndex: 'user',
        title: 'Name',
        key: 'name',
        render: (user) => user?.first_name + ' ' + user?.last_name,
      },
      {
        dataIndex: 'user',
        title: 'Email',
        key: 'email',
        render: (user) => user?.email,
      },
      {
        dataIndex: 'search_query',
        title: 'Search Query',
        key: 'search_query',
      },

      {
        dataIndex: 'search_results',
        title: 'Results',
        key: 'results',
        render: (results) => results.length,
      },
    ],
    [],
  )

  const expandable = useMemo<ITableProps<ILogSearch>['expandable']>(
    () => ({
      defaultExpandAllRows: true,
      expandedRowRender: (record) => (
        <div style={{
          padding: 20,
        }}>
          {record.search_results.length > 0
            ? (
              <SearchResultsTable results={record.search_results}/>
            ) : (
              <div>
                No search results were shown for this search query
              </div>
            )
          }
        </div>
      ),
    }),
    [],
  )

  return (
    <>
      <SearchField/>
      <ConditionRender condition={!loading} component={
        <Table
          className={styles.table}
          data={searchLogs.data}
          columns={columns}
          rowKey={({ id }) => id}
          // onSort={onSort}
          // defaultSort={sortState}
          expandable={expandable}
          pagination={false}
        />
      } secondComponent={<SkeletonTable lines={10}/>}
      />
      <Pagination total={searchLogs.total} pageSize={PAGE_SIZE}/>
    </>
  )
}
