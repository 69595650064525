import { FC, useCallback } from 'react'
import { InputGroup, Input } from '@wmgtech/legato'
import { useController } from 'react-hook-form'
import { validationAdapter } from './helpers'
import { IFormInputProps, IFieldError } from './types'

export const FormInput: FC<IFormInputProps> = ({ name, label, placeholder, required, disabled }) => {
  const { field, formState: { errors } } = useController({ name })

  const validation = useCallback(
    (error?: IFieldError) => ({
      required: required,
      ...validationAdapter(error),
    }),
    [required],
  )

  return (
    <InputGroup label={label} validation={validation(errors[name] as IFieldError)}>
      <Input placeholder={placeholder} {...field} disabled={disabled} crossOrigin="" />
    </InputGroup>
  )
}
