import React, { useCallback, FC } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@wmgtech/legato";
import styles from "./GoBack.module.scss";

const Back: FC = ( ) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onGoBack = useCallback(() => {
    if (location.key === 'default') {
      navigate('/')
    } else {
      navigate(-1)
    }
  }, [navigate, location])

  return (
    <div onClick={onGoBack} className={styles.icon}> <Icon name="arrow-left"/></div>
  )
}

export default Back
