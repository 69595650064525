import { gql, useMutation } from '@apollo/client'

export const CREATE_OKTA_USER = gql`
  mutation($input: OktaUserInput!) {
    createOktaUser(input: $input) {
      id
      profile {
        email
        firstName
        homeOrg
        lastName
        managementCompany
        mobilePhone
        role
      }
      activation
    }
  }
`

export const useCreateOktaUser = () => {
  const [mutate, { data, loading, error }] = useMutation(CREATE_OKTA_USER)

  return { mutate, data, loading, error }
}
