import React, { ChangeEvent, FC, useCallback, useState, useEffect } from 'react'
import { Checkbox, Button, Modal } from "@wmgtech/legato"
import { NavLink } from 'react-router-dom'
import { useMutation } from "@apollo/client"
import { ACCEPT_TERMS } from "./graphql"

import { IUser } from "../../modules/Admin";

import styles from './TermOfUse.module.scss'

export const TermOfUseModal: FC = () => {
    const [closed, setClosed] = useState(false)
    const [confirmed, setConfirmed] = useState(false)

    const [acceptTermsMutation] = useMutation<{ acceptTerms: IUser }>(
        ACCEPT_TERMS, {
            update(cache) {
                cache.modify({
                    fields: {
                        findUserByEmail() {},
                    },
                })
            },
        },
    )

    const onAccept = useCallback(async () => {
        if (confirmed) {
            await acceptTermsMutation()
        }
        setClosed(true)
    }, [confirmed, acceptTermsMutation])

    const onChangeConfirm = useCallback(({ target }: ChangeEvent) => {
        setConfirmed((target as HTMLInputElement).checked)
    }, [setConfirmed])

    return (
        <Modal
            title='Welcome to ACID'
            onClose={() => {}}
            isOpen={!closed}
            shouldCloseOnOverlayClick={false}
            className={styles.modal}
            footer={(
                <div className={styles.content}>
                    <Button
                        containerStyle="solid"
                        colorType="secondary-black"
                        label="Confirm"
                        size="sm"
                        disabled={!confirmed}
                        onClick={onAccept}
                    />
                </div>
            )}
        >
            <div className={styles.text}>
              All information provided is confidential and proprietary to WMG, and any unauthorized use or disclosure is strictly prohibited under the terms and conditions.
            </div>
            <div className={styles.text}>
                <Checkbox
                    label={
                        <>
                            By checking this box, you agree to ACID <NavLink to={`/terms-of-use`} className={styles.item} target="_blank" rel="noopener noreferrer">
                            terms and conditions </NavLink> and <a href="https://privacy.wmg.com/ecom/privacy-policy" className={styles.item}>
                            privacy policy</a>.
                        </>
                    }
                    checked={confirmed}
                    name="confirmation"
                    onChange={onChangeConfirm}
                />
            </div>
        </Modal>
    )
}
