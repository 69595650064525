import { useCallback, useMemo, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { SEARCH_ARTISTS } from '../constants'
import { IArtistSearchInput, IArtist } from '../types'

type TPaginatedResponse<T> = {
  items: ReadonlyArray<T>
  total: number
}

type TArgument<T> = {
  input: T
}

export const useSearchArtist = () => {
  const [getArtistQuery, { loading, data }] = useLazyQuery<{ searchGcdmArtists: TPaginatedResponse<IArtist> }, TArgument<IArtistSearchInput>>(SEARCH_ARTISTS)

  const getArtist = useCallback(
    async (searchTerm: string) => {
      if (typeof searchTerm === 'undefined' || searchTerm.length < 3) return Promise.resolve([])
      try {
        const { data } = await getArtistQuery({
          variables: {
            input: { searchTerm },
          },
        })
        return data?.searchGcdmArtists?.items || []
      } catch (err) {
        console.warn(err)
      }
    },
    [getArtistQuery],
  )

  const options = useMemo(
    () => get(data, ['searchGcdmArtists', 'items']),
    [data],
  )

  const getArtistDebounce = useRef(debounce(getArtist, 500))

  return {
    loading,
    getArtist: getArtistDebounce.current,
    options,
  }
}
