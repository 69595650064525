import React, { FC, useEffect, useState } from 'react'
import cn from 'classnames'
import { BubbleProps } from "./Bubble.props";
import styles from './Bubble.module.scss'

const Bubble: FC<BubbleProps> = ({ text, offsetX = 0, offsetY = 0, type, color, customStyle, loaded }) => {

  return (
    <div className={cn(styles.container, {
      [styles.small]: type === 'small',
      [styles.middle]: type === 'middle',
      [styles.big]: type === 'big',
      [styles.huge]: type === 'huge',
    }, {
      [styles.gray]: color === 'gray',
      [styles.purple]: color === 'purple',
    })} style={{ transform: `translate(${offsetX}px, ${offsetY}px) scale(${loaded ? 1 : 0})`, transition: `.4s .${Math.floor(Math.random() * 8) + 3}s ease-out`, ...customStyle }}>
      {text}
    </div>
  )
}

export default Bubble
