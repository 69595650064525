import { createElement, useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { growl } from "@wmgtech/legato"
import { INFO_MESSAGE } from 'modules/LoginMessage/constants'
import { useUpdateInfoMessage } from './useUpdateLoginMessage'
import { Toast } from "components/Toast"

export const useInfoMessage = () => {
    const { data, loading } = useQuery<{ getActiveInfoMessage: any }>(INFO_MESSAGE)

    const { mutate: mutateMessage, loading: updateLoading, error } = useUpdateInfoMessage()

    const updateMessage = useCallback(async (value: any) => {
        await mutateMessage({
            variables: {
                input: {
                    id: '1',
                    message: value.message,
                    active: value.active,
                },
            },
        })

        growl.success({
            message: createElement(Toast, { message: 'Login Message has been updated successfully' }),
            containerStyle: 'tint',
            colorType: 'success',
            icon: 'check',
        }, { autoClose: 3000 })

    }, [mutateMessage])

    return {
        infoMessage: data?.getActiveInfoMessage,
        updateMessage,
        loading,
        updateLoading,
    }
}
