import React, { FC } from 'react'
import { Bubbles, Heading } from 'components';
import styles from './About.module.scss'

const About: FC = () => {
  return (
    <main className='main'>
      <section className='inner'>
        <Heading heading="About"/>

        <div className={styles.about}>

          <div className={styles.left}></div>
          <div className={styles.right}></div>
          <div className={styles.img}></div>
          <div className={styles.content}>
            <h1 className={styles.welcome}>WELCOME TO ACID</h1>
            <div className={styles.subtitle}>
              <div>
                The new Ad Campaign ID Generator built in-house at Warner
              </div>
              <div>Music, designed to help simplify the ad-buying process for our </div>
              <div>marketing teams.</div>
            </div>
          </div>
        </div>

        <Bubbles />

      </section>
    </main>
  )
}

export default About
