import React, { FC, useCallback } from 'react'
import { InputGroup, Textarea } from '@wmgtech/legato'
import { useController } from 'react-hook-form'
import { validationAdapter } from './helpers'
import { IFormTextareaProps, IFieldError } from './types'

export const FormTextarea: FC<IFormTextareaProps> = ({ name, label, placeholder, className, required, disabled, rows }) => {
    const { field, formState: { errors } } = useController({ name })

    const validation = useCallback(
        (error?: IFieldError) => ({
            required: required,
            ...validationAdapter(error),
        }),
        [required],
    )

    return (
        <InputGroup label={label} validation={validation(errors[name] as IFieldError)} className={className}>
            <Textarea {...field} disabled={disabled} placeholder={placeholder} {...rows && { rows } }/>
        </InputGroup>
    )
}
