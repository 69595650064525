import { useCallback } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'

import { IContext } from '../types'
import { activeLabelVar } from "../../../modulesCommon/store";

export const useAdmin = <T extends Record<string, any>>() => {
  const { reset, handleSubmit, formState } = useFormContext<T>()
  const { setModalStatus, entity, isModalOpen } = useOutletContext<IContext>()

  const handleEdit = useCallback(
    (data: T) => {
      activeLabelVar(data.label)
      reset(data)
      setModalStatus(true)
    },
    [reset, setModalStatus],
  )

  return {
    formState,
    handleSubmit,
    isModalOpen,
    setModalStatus,
    handleEdit,
    entity,
  }
}
