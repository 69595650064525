import { FC, useCallback, useEffect, useState } from 'react'
import { Button, Icon } from '@wmgtech/legato'

import './ScrollToTopButton.scss'
import ConditionRender from 'components/ConditionRender/ConditionRender'

const options = {
  root: null,
  rootMargin: '0px 0px 0% 0px',
  threshold: 1,
};

export const ScrollToTopButton: FC = () => {
  const [visible, setVisible] = useState(false)

  const handleObserve = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const { isIntersecting } = entries[0];
      setVisible(!isIntersecting)
    },
    [],
  )

  useEffect(() => {
    const el = document.querySelector('header')
    const observer = new IntersectionObserver(handleObserve, options);
    if (el) {
      observer.observe(el);
    }

    return () => {
      if (el) {
        observer.unobserve(el);
      }
    }
  });

  const goToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <ConditionRender
      condition={false}
      component={
        <Button onClick={goToTop} icon={<Icon name="arrow-up" prefix="fas" />} size="md" className="btn__to-top" />
      }
    />
  )
}

export default ScrollToTopButton
