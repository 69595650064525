import { FC, useCallback, useMemo } from 'react'
import { Badge, ColumnsType, FilterSelect, Icon, Table } from '@wmgtech/legato'
import { ActionButtons } from '../ActionButtons'
import { getCampaignName, getCreator, ICampaign, TFilterKeys } from 'modules/Campaign'
import { Row } from '../Row'
import { Creator } from '../Creator'
import { CampaignType } from '../CampaignType'
import { ICampaignTableProps } from './CampaignTable.props'
import { DateCell } from './DateCell'
import { useCreatorFilter } from './useCreatorFilter'

import styles from './table.module.scss'

export const CampaignTable: FC<ICampaignTableProps<string>> = ({
  dataReader,
  onSort,
  sortState,
  filter,
  onFilter,
  onDelete,
}) => {

  const columnFilter = useCreatorFilter(filter.creator, (list) => onFilter('creator', list))

  const handleFilter = useCallback(
    (name: TFilterKeys, cb: () => void) => (list: ReadonlyArray<string>) => {
      onFilter(name, list);
      cb()
    },
    [onFilter],
  )

  const handleClear = useCallback(
    (name: TFilterKeys, cb: () => void) => (): void => {
      onFilter(name, []);
      cb()
    },
    [onFilter],
  )

  const filterFactory = useCallback( // TODO: Put this in hook
    <T extends string >(key: TFilterKeys, list: ReadonlyArray<T>, renderer?: (el: T) => JSX.Element) => {
      return {
        isFilterApplied: Boolean(filter[key].length),
        renderFilter: (cb: () => void) => (
          <FilterSelect<T>
            list={list}
            value={filter[key] as ReadonlyArray<T>}
            onClearButtonClick={handleClear(key, cb)}
            onFilterButtonClick={handleFilter(key, cb)}
            getElement={renderer}
          />
        ),
      }
    },
    [filter, handleClear, handleFilter],
  )

    const clearFilters = useCallback(
        () => {
          Object.keys(filter).forEach((name) => {
            onFilter(name as TFilterKeys, []);
          })
        },
        [filter, onFilter],
      )

  const columns = useMemo<ColumnsType<ICampaign>[]>(
    () => [
      {
        dataIndex: 'created_at',
        title: 'Date',
        key: 'created_at',
        sortable: true,
        width: '118px',
        align: 'left',
        className: styles.cell,
        render: (item: string) => <DateCell date={item} />,
      },
      {
        dataIndex: 'artist',
        title: 'Artist',
        key: 'artist',
        sortable: true,
        width: '200px',
        align: 'left',
        className: styles.cell,
      },
      {
        dataIndex: 'product',
        title: 'Product',
        key: 'product',
        sortable: true,
        width: '250px',
        align: 'left',
        className: styles.cell,
      },
      {
        dataIndex: 'type',
        title: 'Type',
        key: 'type',
        sortable: true,
        width: '130px',
        align: 'left',
        filterSelect: ['Single', 'Album'],
        ...filterFactory('type', ['Single', 'Album'], (type) => (
            <div className={styles.option}>
              <CampaignType type={type} />
            </div>
          ),
        ),
        className: styles.cell,
        render: (type, record) => (
          <CampaignType type={record.playlists.length ? 'Playlists' : record.category ? 'Other' : record.type} />
        ),
      },
      {
        dataIndex: 'reach',
        title: 'Department',
        key: 'reach',
        sortable: true,
        width: '130px',
        align: 'center',
        ...filterFactory('reach', ['Domestic', 'International']),
        className: styles.cell,
        render: (item: string) => <Badge text={item} colorType={item === 'Domestic' ? "dark" : "primary"} />,
      },
      {
        dataIndex: 'info',
        title: 'Additional Info',
        key: 'info',
        sortable: true,
        width: '220px',
        align: 'left',
        className: styles.cell,
      },
      {
        dataIndex: 'creator',
        title: 'Creator',
        key: 'creator',
        sortable: true,
        width: '120px',
        align: 'center',
        ...columnFilter,
        className: styles.cell,
        render: (_, record) => <Creator name={getCreator(record)} />,
      },
      {
        className: styles.action,
        title: <div className={styles.clear}><Icon name="arrow-rotate-left" onClick={clearFilters}/></div>,
        width: '80px',
        render: (_, record) => (
          <ActionButtons
            copyText={getCampaignName(record)}
            onDelete={() => onDelete({
              data: record.id,
              message: getCampaignName(record),
            })}
          />
        ),
      },
    ],
    [columnFilter, filterFactory, onDelete, clearFilters],
  )

  const expandable = useMemo(
    () => ({
      defaultExpandAllRows: true,
      expandedRowRender: (record: ICampaign) => <Row data={record} />,
    }),
    [],
  )

  return (
    <Table
      className={styles.table}
      data={dataReader}
      columns={columns}
      rowKey={({ id }) => id}
      expandable={expandable}
      onSort={onSort}
      defaultSort={sortState}
      pagination={false}
    />
  )
}
