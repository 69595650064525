import React, { FC } from 'react'
import { Checkbox } from '@wmgtech/legato'
import { useController } from 'react-hook-form'
import { IFormTextareaProps } from './types'

export const FormCheckbox: FC<IFormTextareaProps> = ({ name, label, className, disabled }) => {
    const { field:  { ref, ...restField } } = useController({ name })

    return (
        <Checkbox label={label} {...restField} disabled={disabled} className={className} checked={restField.value} />
    )
}
