import { FC } from 'react'
import styles from './toast.module.scss'

interface IProps {
  title?: string
  message: string
}

export const Toast: FC<IProps> = ({ title = 'Success', message }) => {
  return (
    <>
      <h3 className={styles.title}>{title}</h3>
      <p>{message}</p>
    </>
  )
}
