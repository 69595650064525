import { gql, useMutation } from '@apollo/client'

export const UPDATE_INFO_MESSAGE = gql`
    mutation($input: InfoMessageUpdate) {
        updateInfoMessage(input: $input) {
            id
            active
        }
    }
`

export const useUpdateInfoMessage = () => {
    const [mutate, { data, loading, error }] = useMutation(UPDATE_INFO_MESSAGE)

    return { mutate, data, loading, error }
}
