import React from 'react'
import { FC } from 'react'
import '../Skeleton.scss'

const Skeleton: FC<{ lines?: number }> = ({ lines = 1 } ) => {
  const rows = [];
  for (let i = 0; i < lines; i++) {
    rows.push(<div key={i} className="pd-skeleton__line pd-skeleton__title" />);
  }

  return (
    <div className="pd-skeleton__flex">
      {rows}
    </div>
  )
}

export default Skeleton
