import set from 'lodash/fp/set'
import { ITeamListInput } from './types'
import { PAGE_PARAM, SEARCH_TERM_PARAM, SORT_DIRECTION, SORT_FIELD_PARAM } from "./constants";


export function getCurrentPage(searchParameter: URLSearchParams): number {
  if (searchParameter.has(PAGE_PARAM)) {
    const pageString = searchParameter.get(PAGE_PARAM)
    if (typeof pageString === 'string') {
      const page = parseInt(pageString, 10)
      if (!isNaN(page)) return page
    }
  }
  return 1
}

export function searchTerm<T extends ITeamListInput>(params: URLSearchParams) {
  return (data: T): T => {
    if (params.has(SEARCH_TERM_PARAM)) {
      const search = params.get(SEARCH_TERM_PARAM)
      return set(['input', 'search'])(search)(data)
    }
    return data
  }
}

export function sortParams<T extends ITeamListInput>(params: URLSearchParams) {
  return (data: T): T => {
    if (params.has(SORT_FIELD_PARAM)) {
      return set(['input', 'sortOrder'])({
        direction: params.get(SORT_DIRECTION),
        field: params.get(SORT_FIELD_PARAM),
      })(data)
    }
    return data
  }
}

export function pageParams<T extends ITeamListInput>(params: URLSearchParams) {
  return (data: T): T => {
    if (params.has(PAGE_PARAM)) {
      const offset = (getCurrentPage(params) - 1) * data.input.limit
      return set(['input', 'offset'])(offset)(data)
    }
    return data
  }
}
