import { FC } from 'react'
import { format } from 'date-fns'


interface Props {
  date?: string
}

export const DateCell: FC<Props> = ({
  date = new Date().toISOString(),
}) => {
  return <>{format(new Date(date), 'MM/dd/yyyy')}</>
}
