import { FC, useCallback, useRef, useState } from 'react'
import { Label, IconButton } from '@wmgtech/legato'
import styles from './copy-field.module.scss'

interface IProps {
  label: string
  text: string
}

export const CopyField: FC<IProps> = ({ label, text }) => {

  const content = useRef<HTMLInputElement>(null)

  const [isPending, setStatus] = useState<boolean>(false)

  const handleClick = useCallback(
    () => {
      if (content.current) {
        content.current.focus()
        content.current.select()
        try {
          document.execCommand('copy')
          setStatus(true)
          setTimeout(() => { setStatus(false) }, 3000)
        } catch (err) {
          console.warn('Oops, unable to copy')
        }
      }
    },
    [],
  )
  return (
    <div className={styles.container}>
      <Label text={label} className='lgt-input-group__label' />
      <div className='lgt-input--icons-1 lgt-input lgt-input--md lgt-input--outline'>
        <input
          ref={content}
          className='lgt-input__control'
          type="text"
          defaultValue={text}
          readOnly
        />
        {
        isPending ? (
          <IconButton
            icon="check"
            message="Campaign name copied successfully!"
            colorType="success"
            className={styles.btn}
          />
          ) : (
          <IconButton
            icon="copy"
            message="Copy full campaign name."
            colorType="primary"
            onClick={handleClick}
            className={styles.btn}
          />
        )
      }
      </div>
    </div>
  )
}
