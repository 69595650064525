import { FC, useCallback, useEffect, useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { InputGroup, AsyncSelect } from '@wmgtech/legato'
import compact from 'lodash/fp/compact'
 
import { useSearchArtist, IArtist } from 'modules/Campaign'
import { validationAdapter, IFieldError } from 'components/Fields'
import { SearchItem } from './SearchItem'

export const FormSearchArtist: FC<{ isRequired?: boolean }> = ({ isRequired }) => {
  const [isOverride, setOverrideFlag] = useState(false)
  const { watch, setValue } = useFormContext()
  const { field: { onChange, ref, ...rest }, formState: { errors } } = useController({ name: 'artist' })
  const { options, loading, getArtist } = useSearchArtist()
  const product: IArtist = watch('product')

  useEffect(() => {
    // TODO: need rewrite. It uses for rerenderAsyncSelect if we set value dynamicly to form values.//
    // in this case if we choose product value, then we should override artist with product's artist value
    if (rest.value?.setByProduct) {
      onChange({ ...rest.value, setByProduct: undefined })
      setOverrideFlag(true)
    }
  }, [rest.value, onChange])
  
  const handleChange = useCallback(
    (data: IArtist) => {
        onChange(data !== undefined ? data : '')

        if (data?.artistId && product?.artistId && data.artistId !== product.artistId) {
            setValue('product', undefined)
        }
    },
    [onChange, product?.artistId, setValue],
  )
    
  const validation = useCallback(
    (error?: IFieldError) => ({
      required: isRequired,
      pending: loading,
      ...validationAdapter(error),
    }),
    [loading, isRequired],
  )

  const handleFilter = useCallback(
    (term: string) => {
      getArtist(term)
      setOverrideFlag(false)
    },
    [getArtist],
  )

  return (
    <InputGroup label="Artist" validation={validation(errors['artist'] as IFieldError)}>
      <AsyncSelect<IArtist>
        onSelect={handleChange}
        isClearable
        placeholder="Select Artist"
        onSearch={handleFilter}
        isLoading={loading}
        list={isOverride ? undefined : options}
        key={rest.value?.setByProduct}
        getKey={(artist) => artist?.artistId}
        getTitle={(artist) => artist?.artistDisplayTitle}
        getElement={(artist) => (
          <SearchItem
            title={artist.artistDisplayTitle}
            subtitle={compact([artist.genre, artist.country, artist.company]).join(' | ')}
          />)
        }
        {...rest}
      />
    </InputGroup>
  )
}
