import { FC, useCallback, useRef, useState } from 'react'
import { IconButton } from '@wmgtech/legato'
import styles from './copy-to-clipboard.module.scss'

interface IProps {
  text: string
  className?: string
}

export const CopyToClipboard: FC<IProps> = ({ text, className }) => {
  const content = useRef<HTMLTextAreaElement>(null);

  const [isPending, setStatus] = useState<boolean>(false)

  const handleClick = useCallback(
    () => {
      if (content.current) {
        content.current.focus()
        content.current.select()
        try {
          document.execCommand('copy')
          setStatus(true)
          setTimeout(() => {
            setStatus(false)
          }, 3000)
        } catch (err) {
          console.warn('Oops, unable to copy')
        }
      }
    },
    [],
  )

  return (
    <>
      <textarea
        tabIndex={-1}
        onChange={() => null}
        ref={content}
        className={styles.content}
        value={text}
      />
      {
        isPending ? (
          <IconButton
            icon="check"
            message="Campaign name copied successfully!"
            colorType="success"
            className={className}
          />
        ) : (
          <IconButton
            icon="copy"
            message="Copy full campaign name."
            colorType="primary"
            onClick={handleClick}
            className={className}
          />
        )
      }
    </>
  )
}
