import { FC } from 'react'
import { IconButton } from '@wmgtech/legato'
import { CopyToClipboard } from 'components/CopyToClipboard'
import styles from './action-buttons.module.scss'

interface IProps {
  copyText: string
  onDelete(): void
}

export const ActionButtons: FC<IProps> = ({ copyText, onDelete }) => {
  return (
    <div className={styles.container}>
      <CopyToClipboard text={copyText} className={styles.btn} />
      <IconButton
        colorType="danger"
        message="Delete"
        icon="trash-can"
        className={styles.btn}
        onClick={onDelete}
      />
    </div>
  )
}