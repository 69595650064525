import React, { FC } from 'react'
import { ILogSearchResult } from "../../types";
import styles from './SeachResultsTable.module.scss'

type TSearchResultsTableProps = {
  results: ILogSearchResult[]
}

export const SearchResultsTable: FC<TSearchResultsTableProps> = ({ results }) => {
  return (
    <div className={styles.table}>
      <table style={{
        borderBottom: '2px solid #ccc',
      }}>
        <thead>
        <tr>
          <th>Artist name</th>
          <th>Product type</th>
          <th>Product</th>
        </tr>
        </thead>
        <tbody>
        {results.map((result, index) => (
          <tr key={index} style={{
            borderBottom: '1px solid #ccc',
          }}>
            <td>{result.artistName}</td>
            <td>{result.productType}</td>
            <td>{result.product}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
}
