import React, { FC } from 'react'
import { Accordion } from "@wmgtech/legato";
import styles from './FAQ.module.scss'
import Heading from "../../components/Heading/Heading";

const FAQ: FC = () => {
  return (
    <main className='main'>
      <section className='inner'>
        <Heading heading="FAQ"/>
        <Accordion className={styles.accordion} list={[
          {
            title: "What is ACID?",
            content: "ACID stands for Ad Campaign ID Generator. ACID was developed internally by Warner Music developers to help make things easier for our marketing teams, by giving quick access to automatically generated IDs for all their marketing campaigns.",
            expand: false,
            id: 1,
          }, {
            title: "Who is ACID meant for?",
            content: "ACID will speed things up for anyone who is doing ad-buying in any team or environment. No more manually looking up ISRC numbers, just use the generator to automatically create a random ID for your campaign. You can add notes or custom information to help keep things organized. Marketing managers and label admins have access to the entire history of previously generated campaign IDs, so that nothing ever gets lost.",
            expand: false,
            id: 2,
          }, {
            title: "Will ACID take extra time to use?",
            content: "ACID is designed to be as simple as possible, and can be easily incorporated into your existing ad-buying workflow. No additional steps are needed – rather, we hope you will experience an increase in productivity instead!",
            expand: false,
            id: 3,
          }, {
            title: "How do I get access to ACID?",
            content: "You can gain access to ACID by contacting your local marketing manager. If your team needs to be onboarded to the software or you would like additional information or training, you can reach out to acid-support@warnermusic.com",
            expand: false,
            id: 4,
          }, {
            id: 5,
            title: "What if I have multiple campaigns running concurrently with different objectives?",
            content: "We’d recommend using different ACID IDs if the objective is different across the different campaigns.",
            expand: false,
          }, {
            id: 6,
            title: "What if my campaign is for a playlist?",
            content: "We recommend selecting the Artist Name, and adding the Playlist to the Additional Info field. If you’re promoting a topical or thematic Playlist, we’d recommend not including ACID at this time, while still following the naming conventions as closely as possible. We plan to have a feature for this in the coming months!",
            expand: false,
          }, {
            id: 7,
            title: "What if I create different campaigns in the same product/platform for different countries?",
            content: "As the campaign objective is the same, we recommend using the same ACID ID across the different country campaigns.",
            expand: false,
          }, {
            id: 8,
            title: "What if my partner agency can’t find an ACID ID?",
            content: "When they are not able to generate the ID through exact matching, or find the product, we’d recommend they reach out to their Marketing Manager to see if they are able to create an ID for them. Or, contact ACID support.",
            expand: false,
          }, {
            id: 9,
            title: "What if I can’t find an ACID ID?",
            content: <p>If the ACID ID is not available internally, it means that we do not yet have the product
              information available in GCDM. When the ACID ID isn’t available, we recommend using the following naming
              convention, with an ACID ID holding code 000000 which you can replace closer to the release date when the
              ACID ID it is available.
              <ul>
                <li> Naming Convention Template: [DOM / INTL] - Artist Name - Track Name - 00000 - Additional
                  Information that is helpful
                </li>
                <li>Artist Exmaple: INTL - Ed Sheeran - Dive - 1UYNV2 - Phase 3 YouTube Spend</li>
              </ul>
            </p>,
            expand: false,
          },
        ]}/>
      </section>
    </main>
  )
}

export default FAQ
