import { FC } from 'react'
import { ButtonGroup } from '@wmgtech/legato'
import { NavLink } from 'react-router-dom'
import cn from 'classnames';
import { config, TEntities } from 'modules/Admin'
import styles from './navigation.module.scss'

interface IProps {
  entities: TEntities[]
}

export const Navigation: FC<IProps> = ({ entities }) => {

  return (
    <ButtonGroup size='md'>
      {
        entities.map(e => (
          <NavLink
            to={`/admin/${e.toLowerCase()}`}
            key={e}
            className={({ isActive }) => cn(styles.link, { [styles.active]: isActive })}
          >{config[e].title}</NavLink>
        ))
      }
    </ButtonGroup>
  )
}
