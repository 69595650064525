import { useCallback } from 'react'
import { Security } from '@okta/okta-react'
import { useNavigate } from 'react-router-dom'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'

import AuthApolloProvider from 'apollo/AuthApolloProvider'

import { MainLayout } from 'modulesCommon/MainLayout/containers'
import { oktaAuthConfig } from 'shared/config'

const oktaAuth = new OktaAuth(oktaAuthConfig)

const App = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = useCallback(
    (_oktaAuth: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin))
    },
    [navigate],
  )

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AuthApolloProvider>
        <MainLayout/>
      </AuthApolloProvider>
    </Security>
  )
}

export default App
