import { FC, useCallback, useMemo } from 'react'

import { useTeams } from 'modules/Teams'
import { ColumnsType, ITeam, ITeamForm, ModalForm, useAdmin } from 'modules/Admin'
import { SearchField } from "../../../../components/Fields";
import { Table } from "@wmgtech/legato";
import styles from "../../../Users/components/Users/user.module.scss";
import { Pagination } from "../../../../components/Pagination";
import { ActionButton } from "../../../../components/ActionButton";
import { ConditionRender, SkeletonTable } from "../../../../components";

function teamsAdapter({ name, label_id, id }: ITeam): ITeamForm {
  return {
    id,
    name,
    label: label_id,
  }
}

const PAGE_SIZE = 10


export const Teams: FC = () => {
  const { teams, loading, onSort, sortState, createTeam, updateTeam } = useTeams(PAGE_SIZE)

  const { handleEdit } = useAdmin()


  const onSubmit = useCallback(
    async ({ id, name, label }: ITeamForm) => {
      if (typeof id === 'undefined') {
        return createTeam({ name, label_id: label })
      }
      return updateTeam({ name, id })
    },
    [createTeam, updateTeam],
  )

  const columns = useMemo<ColumnsType<ITeam>[]>(
    () => [
      {
        dataIndex: 'name',
        title: 'Team',
        key: 'team',
        sortable: true,
        width: '300px',
        align: 'left',
      },
      {
        dataIndex: 'label',
        title: 'Label',
        key: 'label',
        sortable: true,
        width: '800px',
        align: 'left',
        render: (_, record) => record?.label?.name,
      },
      {
        dataIndex: 'action',
        key: 'action',
        sortable: false,
        width: '63px',
        align: 'center',
        render: (el: any, record: any) => (
          <ActionButton
            handleEdit={() => handleEdit(teamsAdapter(record as any))}
          />
        ),
      },
    ],
    [handleEdit],
  )


  return (
    <>
      <SearchField/>

      <ConditionRender condition={!loading} component={
      <Table
        className={styles.table}
        data={teams.data}
        columns={columns}
        rowKey={({ id }) => id}
        onSort={onSort}
        defaultSort={sortState}
        pagination={false}
      />
      } secondComponent={<SkeletonTable lines={10}/>}
      />

      <Pagination total={teams.total} pageSize={PAGE_SIZE}/>
      <ModalForm onSubmit={onSubmit}/>
    </>
  )
}
