import { ChangeEventHandler, FC, useCallback, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { Input } from '@wmgtech/legato'
import styles from './search-field.module.scss'

export const SearchField: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [value, setValue] = useState(searchParams.get('term') || '')

  const updateQueryParams = useCallback(
    (term: string) => {
      if (term.length >= 3) {
        setSearchParams((prev) => {
          prev.set('term', term);
          return prev;
        })
      } else {
        setSearchParams((prev) => {
          prev.delete('term');
          return prev
        })
      }
    },
    [setSearchParams],
  )

  const debouncedUpdateQueryParams = useRef(debounce(updateQueryParams, 500))

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const term = event.currentTarget.value
      setValue(term)
      debouncedUpdateQueryParams.current(term)
    },
    [debouncedUpdateQueryParams],
  )

  return (
    <Input
      onChange={handleChange}
      placeholder="Search"
      value={value}
      className={styles.field}
      suffixIcon="magnifying-glass"
      isClearable
      crossOrigin=""
    />
  )
}
