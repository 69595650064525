import { FC } from 'react'
import { Button } from '@wmgtech/legato'

interface IProps {
  name: string
  handleOpen(): void
}

export const AddButton: FC<IProps> = ({ name, handleOpen }) => {

  return (
    <Button
      containerStyle='outline'
      icon="plus"
      colorType="info"
      onClick={handleOpen}
      >New {name}
    </Button>
  )
}
