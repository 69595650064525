import { gql } from "@apollo/client"

export const INFO_MESSAGE = gql`
    query {
        getActiveInfoMessage {
            id
            message
            active
        }
    }
`