import React, { FC, useEffect } from 'react'
import { ApolloProvider, ApolloClient, HttpLink, concat, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import { useOktaAuth } from '@okta/okta-react'

import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { subsAWSConfig } from 'shared/config'

import { cache } from 'apollo/cache'

import { tokenVar } from 'modulesCommon/store'

interface IProps {
  children: React.ReactNode
}

const AuthApolloProvider: FC<IProps> = ({ children }) => {
  const { authState } = useOktaAuth()

  const authMiddleware = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: authState?.idToken?.idToken || '',
    },
  }))

  const httpLink = new HttpLink({ uri: process.env.REACT_APP_API_URL })

  const subsAWSLink = createSubscriptionHandshakeLink(subsAWSConfig(authState?.idToken?.idToken || ''), httpLink)

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)

      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    subsAWSLink,
    concat(authMiddleware, httpLink),
  )

  const client = new ApolloClient({
    cache,
    link: splitLink,
    connectToDevTools: true,
  })

  useEffect(() => { tokenVar(authState?.idToken?.idToken) }, [authState?.idToken])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default AuthApolloProvider
