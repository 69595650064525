import React, { FC, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Button } from '@wmgtech/legato'
import { FormTextarea } from "components/Fields/FormTextarea"
import { FormCheckbox } from "components/Fields/FormCheckbox"
import { useInfoMessage } from "../../services/useLoginMessage";

import styles from './login-message.module.scss'

export const LoginMessage: FC = () => {
    const { infoMessage, updateMessage, loading, updateLoading } = useInfoMessage()
    const onSubmit = useCallback(async (value: any) => {
        await updateMessage(value)
    }, [updateMessage])

    const methods = useForm({
        defaultValues: {
            id: '1',
            message: infoMessage?.message ?? '',
            active: infoMessage?.active ?? '',
        },
    })

    return (
        <FormProvider {...methods}>
            <form className={styles.container} onSubmit={methods.handleSubmit(onSubmit)}>
                <h2 className={styles.title}>Message</h2>
                <FormTextarea
                    label=""
                    name="message"
                    required={false}
                    className={styles.textarea}
                    rows={7}
                    placeholder='This text area informs users of situations that might affect their usage of ACID'
                />
                <div className={styles.footer}>
                    <FormCheckbox label='"Active" status to show or hide message' name="active" />
                    <Button htmlType='submit' disabled={!!updateLoading}>Save</Button>
                </div>
            </form>
        </FormProvider>
    )
}
